import { Badge, Chip, Divider, TextField } from '@mui/material';
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from 'react'
import { dataProvider, getProfile, formatter, searchGlobalProduct } from "../util";
import { Inventory } from '@mui/icons-material';
import Swal from 'sweetalert2';

export default function ProductSearch(props) {
    const [formData, setFormData] = React.useState({});
    const [product, setProduct] = React.useState({});
    const [productSearch, setProductSearch] = React.useState([]); //clear on initital

    useEffect(() => {
        populateProduct();
    }, [product])


    const updateProductState = (e) => {
        if (e.target.value.length >= 3) {
            // if (e.target.value.length % 3 === 0) {
            setProduct({ ...product, [e.target.name]: e.target.value });
        }

    };
    const populateProduct = async () => {

        let id = null;
        if (product && product["search"]?.length > 1) {
            id = product["search"];
        } else {
            return false;
        }

        try {
            const data = await searchGlobalProduct(id);
            let mydata = data.data;

            if (typeof mydata === "undefined") {
                setProductSearch([]);
                return false;
            }
            // console.log(data.data[0])
            if (mydata[0] && mydata[0]["title"]) {
                setProductSearch(mydata);
            } else {
                setProductSearch([]);
            }
        } catch (error) {
            console.error(error);
        }

        //
    };


    return (
        <div>
            <Autocomplete

                id="product_search"
                //   onChange={productUpdate}
                onChange={(event, newValue) => {
                    // setFetchedData(newValue)
                    // setFormData({
                    //     id: newValue?.id,
                    //     stock: null,
                    //     receival: true
                    // })
                    if(newValue !== null  && newValue.stock < 1){
                        return  Swal.fire({
                            icon: 'warning',
                            text: 'Inventory low, cannot add this product',
                            toast: true,
                            // showConfirmButton: false,
                            timer: 1800,
                            // position: 'bottom-end',
                        })
                    }
                    console.log(newValue, "newValue")
                    props.callback(newValue)


                }}
                options={productSearch}
                getOptionLabel={(option) => {
                    if (option.barcode) {
                        return option.title + ' - ' + option.description + ' / ' + option.barcode
                    } else {
                        return option.title + ' ' + option.description
                    }

                }}
                renderOption={(option) => {
                    return <React.Fragment>

                        <div style={{ color: "#666", margin: 3 }}>
                            <div>{option.title + " "}<Chip size="small" style={{ fontWeight: "bold" }} label={formatter.format(option["price"])} color="success" variant="outlined" />{" "}<Badge badgeContent={option["stock"]} color="primary">
                                <Inventory color="action" />
                            </Badge></div>
                            <small style={{ fontSize: '0.9em', color: '#666' }}>{option["description"]} </small>
                            <div style={{ fontSize: '0.8em', color: '#aaa' }}>{option["barcode"]}</div>

                        </div> <Divider />
                    </React.Fragment>
                }}
                //   style={{ width: 300 }}
                renderInput={(params) => (
                    <TextField
                        // autoFocus
                        {...params}
                        label="Find product"
                        variant="outlined"
                        placeholder="Search by barcode, title, id, description"
                        value={product.search}
                        name="search"
                        onChange={updateProductState}
                    />
                )}
            />
        </div>
    )
}
