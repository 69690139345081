// import { Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Dialog, DialogTitle, DialogActions, Button, DialogContentText, DialogContent, Grid, Autocomplete, TextField, AppBar, Toolbar, IconButton, Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react'
import Swal from 'sweetalert2';
import { dataProvider } from '../util';

export default function AddCustomer(props) {
    console.log("Add Cust", props)
    const { opener } = props;
    const [formData, setFormData] = useState({});
    const [open, setOpen] = useState(opener);

    useEffect(() => {
        setOpen(opener)
    }, [opener])
    function handleClose() {
        props.closer(false)
        setOpen(prev => !prev)
    }

    const formFields = [
        {
            id: 'First name',
            label: 'First name',
            required: true,
            type: 'text'
        }, {
            id: 'Last name',
            label: 'Last name',
            required: true,
            type: 'text'
        }, {
            id: 'Contact number',
            label: 'Contact',
            required: true,
            type: 'text'
        }, {
            id: 'VIN',
            label: 'VIN',
            required: true,
            type: 'text'
        }, {
            id: 'Registration Number',
            label: 'Registration number',
            required: true,
            type: 'text'
        }, {
            id: 'Email',
            label: 'Email',
            required: false,
            type: 'text'
        }
    ]
    const handleFormDataChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const reset = () => {
        Array.from(document.querySelectorAll("input")).forEach(
            (input) => (input.value = "")
        );
        setFormData({});
    };
    function handleSubmit(e) {
        e.preventDefault();
        dataProvider('post', '/customer', formData)
            .then((res) => {
                console.log(res)
                if (res.data.value === true) {

                    reset();

                    return Swal.fire({
                        text: res.data.message,
                        icon: 'success',
                        toast: true,
                        showConfirmButton: false,
                        position: 'bottom-end',
                        'timer': 2000,
                    })

                }

                return Swal.fire({
                    text: res.data.message,
                    icon: 'warning',
                    toast: true,
                    // showConfirmButton: false,
                    // position: 'bottom-end',
                    // 'timer': 2000,
                })

            })
            .catch((error) => {
                console.error(error)
            });
    }

    return (
        <Dialog
            maxWidth="lg"
            fullWidth

            onClose={handleClose} open={open}>
            <AppBar sx={{ position: "relative" }}>
                <Toolbar>

                    <Box display="flex" flexGrow={1}>
                        {/* <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                > */}
                        Add Customer
                        {/* </Typography> */}
                    </Box>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <form autoComplete="off" onSubmit={handleSubmit}>
                {/* <DialogTitle>{title}</DialogTitle> */}


                <DialogContent>
                    <DialogContentText style={{ padding: '10px 0 10px 0' }}>
                        Please enter new customer details
                    </DialogContentText>

                    {/* <Box
            className={classes.form_Root}
            justifyContent="center" m={3} p={3}
          > */}

                    <Grid container spacing={2}>
                        {
                            formFields.map(i =>
                                <Grid item xs={12} md={6} key={i.id}>

                                    {i.type === 'select' ? <>
                                        <Autocomplete
                                            // value={formData.supplier}
                                            clearOnBlur={false}
                                            label={i.label} name={i.id}
                                            options={i.data}
                                            getOptionLabel={(option) => option.bizname}
                                            // onChange={handleFormDataChange}
                                            onChange={(event, newValue) => {
                                                newValue && i.id && newValue.id && setFormData({ ...formData, [i.id]: newValue.id });
                                            }}
                                            style={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} label={i.label} name={i.name} variant="outlined" />}
                                        />
                                    </> :
                                        <TextField onKeyPress={e => e.key === 'Enter' && e.preventDefault()} type={i.type} required={i.required} fullWidth variant="outlined" onChange={handleFormDataChange} label={i.label} name={i.id} />}
                                </Grid>)
                        }

                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>
                        Close
                    </Button>
                    <Button type="submit" variant="contained" color='info'>Save</Button>
                </DialogActions> </form>
        </Dialog>
    )
}
