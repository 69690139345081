import axios from 'axios'
import { getToken } from '../util';

let domain = "https://do-api.scottsautoja.com/"
if (process.env.NODE_ENV === "development") {
    domain = "http://localhost:1980/";
  }
  

const  api = axios.create({
    // baseURL: 'http://localhost:3009/api'
    baseURL: `${domain}v2/inventory`,
    headers: { Authorization: "Bearer " + getToken() }
});


// export const getCharges = async() => {
//     const response = await api.get("/charges")
//     return response.data
// }

// export const getChargeTypes = async() => {
//     const response = await api.get("/chargeTypes")
//     return response.data
// }

// export const getChargesTable = async(p) => {
//     const response = await api.get(`/charges_${p}`)
//     // console.log(response.data.data, "SP")
//     return response.data
// }
// export const getChargeTypesTable = async(p) => {
//     const response = await api.get(`/chargeTypes_${p}`)
//     // console.log(response.data.data, "SP")
//     return response.data
// }

export const addInventoryLog = async (value) =>  api.post("inventory/log", value)

export const getInventoryLog = async(value) => {
    const response = await api.get("/log/"+value)
    return response.data
}

export const addPhoto = async (value) =>  api.post("/addPhoto", value)

export const getPhoto = async(value) => {
    const response = await api.get("/getPhoto/"+value)
    return response.data
}

export default api;