import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Bar from "./components/Bar";
import AddCustomer from "./pages/AddCustomer";
import AddProduct from "./pages/AddProduct";
import ViewCustomers from "./pages/ViewCustomers";
import ViewProducts from "./pages/ViewProducts";
import ViewInvoices from "./pages/ViewInvoices";
import ViewEstimate from "./pages/ViewEstimate";
import ViewEstimates from "./pages/ViewEstimates";
import ViewInvoice from "./pages/ViewInvoice";
import ServiceRequest from "./pages/ServiceRequest";
import Dashboard from "./pages/Dashboard";
import ViewCalendar from "./pages/ViewCalendar";
import Transactions from "./pages/Transactions";
import MakeSale from "./pages/MakeSale";
// import EditServices from "./pages/EditServices";
import Login from "./pages/Login";
import Bookings from "./pages/Bookings";
// import Footer from "./components/Footer";
// import {checkAuth} from './util';
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
// import { getProfile } from "../util";
import { getProfile, versionCheck } from "./util";
import GenerateInvoice from "./pages/GenerateInvoice";
import Report from "./pages/Report";
import ViewMembers from "./pages/ViewMembers";
import ViewSysLog from "./pages/ViewSysLog";
import ViewSuppliers from "./pages/ViewSuppliers";
import ViewConfig from "./pages/ViewConfig";
import Products from "./pages/Products";
// import { dataProvider } from "./util";
// import withClearCache from "./ClearCache";

// const ClearCacheComponent = withClearCache(MainApp);

// function App() {
//   return <ClearCacheComponent />;
// }

// function MainApp(props) {

import {ErrorBoundary} from 'react-error-boundary' 
import ViewInventoryLog from "./pages/ViewInventoryLog";

function ErrorFallback({error, resetErrorBoundary}) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={()=>{window.location.href = '/'}}>Try again</button>
      {/* <button onClick={resetErrorBoundary}>Try again</button> */}
    </div>
  )
}



function App() {
  const [profile, setProfile] = React.useState({});
  useEffect(() => {
    versionCheck();
    loadProfile();
  }, []);

  async function loadProfile() {
    const profile = await getProfile();
    setProfile(profile);
  }



  return (
    <div className="App">

<ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={() => {
      //console.log('reset--------------------------------------------')
      // reset the state of your app so the error doesn't happen again
    }}
  >


      <Router>
        <Bar />
        <Switch>
          <PrivateRoute
            component={profile.permission > 2 ? Dashboard : ServiceRequest}
            path="/"
            permission={1}
            exact
          />
          <PrivateRoute
            component={ServiceRequest}
            path="/make-request"
            permission={1}
            exact
          />
          <PrivateRoute
            component={Dashboard}
            permission={2}
            path="/dash"
            exact
          />
          <PrivateRoute
            component={ViewCalendar}
            permission={2}
            path="/view-calendar"
            exact
          />
          <PrivateRoute
            component={Bookings}
            permission={3}
            path="/view-request"
            exact
          />
          {/* <PrivateRoute
            component={EditServices}
            permission={3}
            path="/edit-services"
            exact
          /> */}
          <PrivateRoute
            component={ViewConfig}
            permission={5}
            path="/view-config"
            exact
          />
           <PrivateRoute
            component={Report}
            permission={3}
            path="/report"
            exact
          />
          <PrivateRoute
            component={AddCustomer}
            permission={1}
            path="/add-customer"
            exact
          />
          <PrivateRoute
            component={ViewCustomers}
            permission={3}
            path="/view-customers"
            exact
          />
          <PrivateRoute
            component={AddProduct}
            permission={3}
            path="/add-product"
            exact
          />{" "}
          <PrivateRoute
            component={ViewProducts}
            permission={3}
            path="/view-products"
            exact
          />
           <PrivateRoute
            component={Products}
            permission={4}
            path="/products"
            exact
          /> 
           <PrivateRoute
            component={ViewSuppliers}
            permission={5}
            path="/view-suppliers"
            exact
          /> 
          <PrivateRoute
            component={GenerateInvoice}
            permission={3}
            path="/generate-invoice"
            exact
          />
           <PrivateRoute
            component={ViewInvoices}
            permission={3}
            path="/view-invoices"
            exact
          />
             <PrivateRoute
            component={ViewEstimate}
            permission={3}
            path="/view-estimate"
            exact
          /> 
           <PrivateRoute
            component={MakeSale}
            permission={4}
            path="/make-sale"
            exact
          />
             <PrivateRoute
            component={ViewEstimates}
            permission={4}
            path="/view-estimates"
            exact
          />
          
           <PrivateRoute
            component={Transactions}
            permission={5}
            path="/transactions"
            exact
          />
          
           <PrivateRoute
            component={ViewInvoice}
            permission={4}
            path="/view-invoice"
            exact
          />
           <PrivateRoute
            component={ViewMembers}
            permission={5}
            path="/view-team"
            exact
          /> 
          
          <PrivateRoute
            component={ViewSysLog}
            permission={5}
            path="/view-sys-log"
            exact
          /> 
          <PrivateRoute
            component={ViewInventoryLog}
            permission={5}
            path="/view-inventory-log"
            exact
          />
          <PublicRoute
            component={Login}
            restricted={true}
            path="/login"
            exact
          />
        </Switch>
      </Router>
    </ErrorBoundary>
 </div>
  );
}
export default App;
