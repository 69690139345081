import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AdaptDialog from "../components/AdaptDialog";
// import MaskText from "../components/MaskText";
import Toast from "../components/Toast";
import { useHistory, useLocation } from "react-router-dom";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Divider,
  LinearProgress,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Chip,
  MenuItem,
  Select,
  InputLabel,
  Switch,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import service_icon from "../car-service.png";
import icon from "../invoice.png";
import {
  dataProvider,
  getPDF_,
  config,
  formatter,
  getProfile,
  printInvoice,
  getSettings,
} from "../util";
import AlertDialog from "../components/AlertDialog";
import moment from "moment";
// import * as Scroll from 'react-scroll';
import { scroller } from "react-scroll";
// import WhatsAppIcon from '@material-ui/icons-material/WhatsApp';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DataTable from "../components/DataTable";
import Swal from "sweetalert2";
import {
  CreditCard,
  Description,
  Money,
  MoneyOff,
  Loyalty,
  PictureAsPdf,
  Print,
} from "@material-ui/icons";
// import { Badge } from "@mui/material";
// import { CurrencyExchange, Inventory, RequestQuote } from "@mui/icons-material";
import ProductSearch from "../components/ProductSearch";
import { GarageOutlined } from "@mui/icons-material";
var _ = require("lodash");

// import transitions from "@material-ui/core/styles/transitions";
// var scroll = animateScroll;
require("dotenv").config();
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 30,
    // background: "#f3f3f3"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  formRoot: {
    "& > *": {
      //   margin: theme.spacing(1),
      //   minWidth: '100%',
      //   background: 'red'
    },
  },
  flow: {
    width: "100%",
  },
  icon: {
    color: "red",
  },
  section1: {
    marginTop: 20,
  },
  subTitle: {
    color: "#0635C9",
    textAlign: "left",
    fontSize: "1.2em",
  },
  hint: {
    color: "#aaa",
  },
}));

export default function ViewInvoice() {
  let history = useHistory();
  const classes = useStyles();

  const [cProfile, setCProfile] = React.useState({});
  const [returnFormData, setReturnFormData] = useState({ comments: "" });
  const [formData, setFormData] = React.useState({});
  const [totals, setTotals] = React.useState({
    product: 0,
    labour: 0,
    total: 0,
  });
  const [payment, setPayment] = React.useState();
  const REDEEM_POINT = process.env.REACT_APP_REDEEM_POINT || 50;

  // const [state, dispatch] = useReducer(reducer, {})
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  // const [preInvoice, setPreInvoice] = React.useState({}); //saved invoice data
  const [serviceRequest, setServiceRequest] = React.useState({});
  const [returnData, setReturnData] = React.useState({});
  const [paid, setPaid] = React.useState(false);
  const [saved, setSaved] = React.useState(true);
  const [products, setProducts] = React.useState([]); //List OF Company Products
  const [invoiceData, setInvoiceData] = React.useState("");
  const [productInvoice, setProductInvoice] = React.useState([]); //Product added to this invoice
  const [checkItems, setCheckItems] = React.useState([]);
  const [selectItems, setSelectItems] = React.useState([]);
  const [option, setOption] = useState({ title: "Products", id: "active" });
  const queryID = useQuery().get("id");

  const [profile, setProfile] = React.useState({ username: "", permission: 0 });
  useEffect(() => {
    loadProfile();
  }, []);

  async function loadProfile() {
    const profile = await getProfile();
    setProfile(profile);
  }

  // Storage
  const [storageState, setStorageState] = useState(false);
  const updateStorage = async (value) => {
    try {
      const mydata = await dataProvider(
        "put",
        "/invoice-storage-state/" + queryID,
        { option: value }
      );
      setToastMessage(mydata.data.message);
      setIsToastOpen(true);
      // loadData();
      // fetchInvoice();
    } catch (error) {
      console.log(error);
      setToastMessage(error.message);
      setIsToastOpen(true);
    }
  };

  //AlertDialog ----------------------------------------
  const [isOpen, setIsOpen] = React.useState(false);
  const handleDialogOpen = () => {
    setIsOpen(true);
  };
  const handleDialogClose = () => {
    setIsOpen(false);
  };
  const handleDialogClose_return = (event) => {
    setIsDialogOpen(false);
  };

  const handleReturnFormChange = (e) => {
    setReturnFormData({ ...returnFormData, [e.target.name]: e.target.value });
  };

  const handleReturnFormChange_chk = (event) => {
    setCheckItems({ ...checkItems, [event.target.name]: event.target.checked });
  };

  const handleReturnFormSelectItems = (e) => {
    setSelectItems({ ...selectItems, [e.target.name]: e.target.value });
  };

  const handleSaveReturn = (e) => {
    e.preventDefault();
    let save_items = [];
    let checked_items = [];
    let updatedProducts = new Array();
    updatedProducts = productInvoice.map((i) => ({ ...i }));

    Object.entries(checkItems).forEach((v, i, a) => {
      if (v[1] === true) {
        //have selected item
        checked_items.push(v[0]);
      }
    });
    let empty = true;
    Object.entries(selectItems).forEach((v, i, a) => {
      if (typeof v[1] === "number" && checked_items.includes(v[0])) {
        let f = _.find(updatedProducts, { id: parseInt(v[0]) });
        if (parseInt(v[1]) > f.qty) return false;
        f.qty = parseInt(v[1]);
        save_items.push(f);
        empty = false;
      } else {
        empty = false;
      }
    });

    if (empty === true) {
      return Swal.fire({
        text: "Please select an item and the amount being returned",
        icon: "warning",
        toast: true,
        showConfirmButton: false,
        position: "bottom-end",
        timer: 3000,
      });
    }

    if (save_items.length != checked_items.length) {
      return Swal.fire({
        text: "Ensure checked items have a quantity",
        icon: "warning",
        toast: true,
        showConfirmButton: false,
        position: "bottom-end",
        timer: 3000,
      });
    }

    Swal.fire(
      {
        // title: "Are you sure?",
        text: "Are you sure you want to continue?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      },
      function (result) {
        if (result.isConfirmed) {
          handleDialogAgree();
        } else {
          return false;
        }
      }
    );
  };

  const paymentDialog = async (trans) => {
    //   Swal.fire({
    //     // title: "Are you sure?",
    //     text: "You have unsaved updates, do you want to save them?",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: '#DD6B55',
    //     confirmButtonText: 'Yes, save changes',
    //     cancelButtonText: "Cancel",
    //  },
    //  function(result){
    // console.log(result.isConfirmed)
    //    if (isConfirm){
    //      handleDialogAgree();
    //     } else {
    //       return false;
    //     }
    //  });

    if (saved === false) {
      await Swal.fire({
        text: "Must save changes before you make a payment.",
        // showDenyButton: true,s
        showCancelButton: true,
        confirmButtonText: "Save Changes",
        // denyButtonText: `Don't save`,
      }).then((result) => {
        if (result.isConfirmed) {
          handleDialogAgree();
          setSaved(true);
          return false;
        } else if (result.isDenied) {
          Swal.fire("Save Changes before making a payment ", "", "info");
          return false;
        }
      });
    } else {
      const { value: payment } = await Swal.fire({
        title: "Receive Payment",
        input: "number",
        html: "<div style='text-align:left;'>Amount to Pay</div>",
        inputValue: totals.total,
        inputAttributes: {
          max: totals.total,
        },
        showCancelButton: true,
        confirmButtonText: "Save Payment",
        inputValidator: (value) => {
          if (!value) {
            return "You need to have a value";
          }
        },
      });

      if (payment) {
        if (payment <= 0) {
          return Swal.fire({
            icon: "warning",
            text: "Invalid payment",
            toast: true,
            showConfirmButton: false,
            timer: 1500,
            // position: 'bottom-end',
          });
        }
        makePayment({
          ...trans,
          invoice_id: queryID,
          amount: payment,
          vin: serviceRequest.vin,
        });
      }
    }
  };

  /**
   * APply Points
   * @param {object} trans
   */
  const applyPoints = async (trans) => {
    var customerProfile = null;

    try {
      const mydata = await dataProvider(
        "get",
        "/customerProfile/" + serviceRequest.vin,
        null
      );
      customerProfile = mydata.data.data;
    } catch (error) {
      console.log(error);
      // reject('Request failed')
      return false;
    }

    const { value: disc } = await Swal.fire({
      title: "Enter points to apply",
      input: "number",
      html: `<div><small>Your current points: <b>${customerProfile.current_points}</b></small></div>`,
      footer: `<div><small id="savingsStatus">-</small></div><div id="calc_discount"></div>`,

      inputValue: customerProfile.current_points,
      // value: customerProfile.current_points,

      didOpen: (dom) => {
        Swal.getInput().onkeyup = function (event) {
          let value = event.target.value;
          dom.querySelector(
            "#savingsStatus"
          ).innerHTML = `Amount in savings: ${(
            value * REDEEM_POINT
          )?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}`;
        };
        Swal.getInput().onchange = function (event) {
          let value = event.target.value;
          dom.querySelector(
            "#savingsStatus"
          ).innerHTML = `Amount in savings: ${(
            value * REDEEM_POINT
          )?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}`;
        };
      },
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "You need to choose something!";
        }
        if (value < 1) {
          return "Invalid points.";
        }
        if (value > customerProfile.current_points) {
          return "You dont have enough points.";
        }
      },
    });

    if (disc) {
      // Swal.fire({ html: `You selected: ${disc}` })
      makeRedemption({
        ...trans,
        points: disc,
        invoice_id: queryID,
        amount: disc * REDEEM_POINT,
        vin: serviceRequest.vin,
      });
    }
  };
  const applyDiscount = async (trans) => {
    const inputOptions = new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          0: "",
          5: "5%",
          6: "6%",
          7: "7%",
          8: "8%",
          9: "9%",
          10: "10%",
          11: "11%",
          12: "12%",
          13: "13%",
          14: "14%",
          15: "15%",
          16: "16%",
          17: "17%",
          18: "18%",
          19: "19%",
          20: "20%",
        });
      }, 200);
    });

    const { value: disc } = await Swal.fire({
      title: "Select discount",
      input: "select",
      inputOptions: inputOptions,
      html: '<div id="calc_discount"></div>',
      didOpen: (dom) => {
        Swal.getInput().onchange = function (event) {
          dom.querySelector("#calc_discount").innerHTML = (
            (totals.labour + totals.product) *
            (event.target.value / 100)
          ).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          });
          // } else {
          //   dom.querySelector('#fruit-text').style.display = 'none'
          // }
        };
      },
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "You need to choose something!";
        }
      },
    });

    if (disc) {
      // Swal.fire({ html: `You selected: ${disc}` })
      makeDiscount({
        ...trans,
        invoice_id: queryID,
        amount: (totals.labour + totals.product) * (disc / 100),
        vin: serviceRequest.vin,
      });
    }
  };

  /* Save Payment */
  const makePayment = async (data) => {
    if (!data) return;

    try {
      const mydata = await dataProvider("post", "/transaction", data);
      setToastMessage(mydata.data.message);
      setIsToastOpen(true);
      // loadData();
      fetchInvoice();
    } catch (error) {
      setToastMessage("Could not make payment, please Save and try again.");
      setIsToastOpen(true);
    }
  };
  /* Save Discount */
  const makeDiscount = async (data) => {
    if (!data) return;

    try {
      const mydata = await dataProvider("post", "/discount", data);
      setToastMessage(mydata.data.message);
      setIsToastOpen(true);
      // loadData();
      fetchInvoice();
    } catch (error) {
      console.log(error);
      setToastMessage("Could not apply discount.");
      setIsToastOpen(true);
    }
  };

  const upMakeDiscount = async (data) => {
    if (!data) return;

    try {
      const mydata = await dataProvider("post", "/upDiscount", data);
      setToastMessage(mydata.data.message);
      setIsToastOpen(true);
      // loadData();
      // fetchInvoice();
    } catch (error) {
      console.log(error);
      setToastMessage("Could not apply automatic discount.");
      setIsToastOpen(true);
    }
  };

  /* Save Redemption */
  const makeRedemption = async (data) => {
    if (!data) return;

    try {
      const mydata = await dataProvider("post", "/redemption", data);
      setToastMessage(mydata.data.message);
      setIsToastOpen(true);
      fetchInvoice();
    } catch (error) {
      console.log(error);
      setToastMessage("Could not apply points.");
      setIsToastOpen(true);
    }
  };

  const scrolly = (ele) => {
    scroller.scrollTo(ele, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -90,
    });
  };
  const handleDialogAgree = () => {
    // reCalculateTotals();
    setIsOpen(false);
    if (!formData.work_hour || formData.work_hour.length < 1) {
      if (serviceRequest?.type === "product") {
        formData.work_hour = 0;
      } else {
        doToast("Enter work hours");
        scrolly("work_hour");
        return false;
      }
    }
    if (!formData.rate_hour || formData.rate_hour.length < 1) {
      if (serviceRequest?.type === "product") {
        formData.rate_hour = 0;
      } else {
        doToast("Enter rate/hour");
        scrolly("rate_hour");
        return false;
      }
    }
    if (totals.total <= 0) {
      return Swal.fire({
        icon: "warning",
        text: "Cannot save a zero invoice",
        toast: true,
        // showConfirmButton: false,
        timer: 3500,
        // position: 'bottom-end',
      });
    }

    let newFormData = { ...formData };
    let newTotalsData = { ...totals };
    // Apply automatic discount
    if (serviceRequest.vin && serviceRequest.vin.length > 1) {
      // const disc_rate = 10;
      // const discount = (totals.labour + totals.product) * (disc_rate / 100)
      // upMakeDiscount({
      //   method: 'discount',
      //   invoice_id: queryID,
      //   amount: (totals.labour + totals.product) * (disc / 100),
      //   vin: serviceRequest.vin,
      // });

      console.log(
        totals.labour + " " + totals.product + " " + formData.discount
      );
      // if we have a discount value
      if (
        cProfile.discount &&
        (cProfile.discount !== null || cProfile.discount !== 0)
      ) {
        const discount =
          (totals.labour + totals.product) * (cProfile.discount / 100);

        setFormData({ ...formData, discount: discount });
        newFormData.discount = discount;

        let newTotal = totals.labour + totals.product - discount;
        // console.log((totals.labour + totals.product), "MAIN TOTS")

        if (payment) {
          newTotal -= payment;
        }

        setTotals({ ...totals, total: newTotal });
        newTotalsData.total = newTotal;
        reCalculateTotals(discount);
      }
    }

    dataProvider("put", "/invoice-details", {
      id: queryID,
      serviceRequest,
      formData: newFormData,
      productInvoice,
      totals: newTotalsData,
    })
      .then((res) => {
        setToastMessage(res.data.message);
        setIsToastOpen(true);
      })
      .catch((error) => {
        console.log(error);
        setToastMessage(error.message);
        setIsToastOpen(true);
        //  window.location = "/login";
      });
  };

  const doToast = (message) => {
    setToastMessage(message);
    setIsToastOpen(true);
  };
  const handleDialogCancel = () => {
    // window.alert('cancel');
    setIsOpen(false);
  };
  // -----------------------------------------------------

  //Toast ----------------------------------------
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const handleToastClose = () => {
    setIsToastOpen(false);
  };
  // -----------------------------------------------------

  //Update work hours Labour Total
  const handleFormChange = (event) => {
    if (event.target.name === "work_hour") {
      updateWorkHour(event.target.value);
    }
    if (event.target.name === "rate_hour") {
      updateRateHour(event.target.value);
    }
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const updateWorkHour = (hours) => {
    if (hours < 0) return false;
    var labour_total = formData.rate_hour * hours;
    /* set product total */
    var product_total = 0;
    productInvoice.forEach((element) => {
      product_total += element.qty * element.price;
    });
    var all_total = labour_total + product_total;
    setTotals({
      ...totals,
      product: product_total,
      total: all_total,
      labour: labour_total,
    });
    setFormData({ ...formData, work_hour: hours });
  };
  const updateRateHour = (rate) => {
    if (rate < 0) return false;
    var labour_total = formData.work_hour * rate;
    /* set product total */
    var product_total = 0;
    productInvoice.forEach((element) => {
      product_total += element.qty * element.price;
    });
    var all_total = labour_total + product_total;
    setTotals({
      ...totals,
      product: product_total,
      total: all_total,
      labour: labour_total,
    });
    setFormData({ ...formData, rate_hour: rate });
  };

  useEffect(() => {
    scrolly("pageTop");

    fetchInvoice();
    // getJob();
    loadProducts(); // Products inventory
    setSaved(true);
  }, []);

  // const saveChanges = () => {
  //   setSaved(true);
  // }

  // Update Totals when product list is modified
  useEffect(() => {
    // var product_total = 0;
    // productInvoice.forEach((element) => {
    //   //  console.log(element, "ele");
    //   product_total += element.qty * element.price;
    // });
    // var all_total = totals?.labour + product_total;
    // setTotals({ ...totals, product: product_total, total: all_total });
    // // setSaved(false);

    reCalculateTotals();
  }, [productInvoice]);

  /**
   * Re-calculate product total and consequently all TOTALS
   * @returns object
   */
  const reCalculateTotals = (discountParam = null) => {
    console.log("recal");
    var product_total = 0;
    productInvoice.forEach((element) => {
      product_total += element.qty * element.price;
    });
    var all_total = totals?.labour + product_total;

    if (payment) {
      all_total -= payment;
    }

    console.log(discountParam, "discountParam");

    if (discountParam !== null) {
      all_total -= discountParam;
      console.log(all_total);
      setFormData({ ...formData, discount: discountParam });
    }
    if (formData.discount && discountParam === null) {
      all_total -= formData.discount;
      console.log(all_total, "formData total");
    }
    setTotals({ ...totals, product: product_total, total: all_total });
  };

  /**
   * Get Job details
   * @returns null
   */
  function getJob(id) {
    // if (!queryID) return;

    dataProvider("post", "/booking", { id: id })
      .then((res) => {
        if (res.message === "Network Error") throw new Error(res.message);
        console.log(res, "booking");
        if (res.data) {
          setServiceRequest(res.data[0]);
        } else {
          alert("No record found " + id);
          //   history.goBack();
        }
      })
      .catch((error) => {
        console.log(error, "post");
        setToastMessage(error);
        setIsToastOpen(true);
        //  window.location = "/login";
      });
  }

  function getCustomer(data) {
    console.log(data, "getCustomer");
    setServiceRequest(data);
  }
  /**
   *
   * @returns Diable invoice udates
   */

  function disableInvoice() {
    // if (payment === 0 && formData.discount === 0) {
    if (payment === 0) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Get Invoice Data
   * @returns object
   */
  function fetchInvoice() {
    if (!queryID) return;

    dataProvider("get", "/invoice/" + queryID, {})
      .then(async (res) => {
        if (res.message === "Network Error") throw new Error(res.message);
        if (res.data) {
          try {
            if (res.data[0].vin && res.data[0].vin !== "-") {
              const cp = await dataProvider(
                "get",
                "/customerProfile/" + res.data[0].vin,
                {}
              );

              setCProfile(cp.data.data);
              // console.log(customerProfile.data.data, "customerProfile")
            }
          } catch (error) {}

          //fetch and set LIVE service requests
          if (JSON.parse(res.data[0].profile)?.type === "product") {
            console.log("product_________________");
            let customerProfile;
            try {
              customerProfile = JSON.parse(res.data[0].profile);
            } catch (error) {
              console.error(error, "parse - invoice");
            }
            getCustomer(customerProfile);
          } else {
            getJob(JSON.parse(res.data[0].profile).id);
          }

          // set storage state
          setStorageState(res.data[0].storage);

          //set products used
          setProductInvoice(JSON.parse(res.data[0].products));

          //set invoiceToken
          setInvoiceData({
            token: res.data[0].token,
            created_at: res.data[0].created_at,
          });
          //update work hour
          updateWorkHour(res.data[0].work_hour);
          //update rate hour
          updateRateHour(res.data[0].rate_hour);
          //set form Data (Work hour)
          setFormData({
            work_hour: res.data[0].work_hour,
            rate_hour: res.data[0].rate_hour,
            discount: res.data[0].discount,
          });

          // let new_total = res.data[0].totals.total - res.data[0].payment;
          // setFullTotal({total:new_total, payment:res.data[0].payment});
          setPayment(res.data[0].payment);
          let totals_obj = JSON.parse(res.data[0].totals);
          setTotals(totals_obj); //apply original saved totals

          //Check if  full payment was made
          if (totals_obj.total <= 0) {
            setPaid(true);
          }
        } else {
          alert("No record found " + queryID);
          //   history.goBack();
        }
      })
      .catch((error) => {
        // console.log(error, "post");
        setToastMessage(error);
        setIsToastOpen(true);
        //  window.location = "/login";
      });
  }

  /**
   * Get all Products
   * @returns object
   */
  async function loadProducts() {
    try {
      const mydata = await dataProvider("get", "/products", []);
      setProducts(mydata.data);
    } catch (error) {
      console.log(error);
      setToastMessage(error.message);
      setIsToastOpen(true);
    }
  }

  function mainTableUp(v) {
    if (v[0]?.qty < 1) return false;

    // for (let item of v) {
    //   if (parseInt(item.qty) > item.stock) {
    //      Swal.fire({
    //       icon: "warning",
    //       text: item.title + " has only (" + item.stock + ") in stock",
    //       toast: true,
    //       // showConfirmButton: false,
    //       timer: 1800,
    //       // position: 'bottom-end',
    //     });
    //     return false;
    //   }
    // }

    setSaved(false);

    console.log(v, "V ITEM");
    console.log(productInvoice, "productInvoice");

    /* set product total */
    var product_total = 0;
    productInvoice.forEach((element) => {
      product_total += element.qty * element.price;
    });
    var all_total = totals?.labour + product_total;
    setTotals({ ...totals, product: product_total, total: all_total });
    setProductInvoice([...v]);

    // setTotals({...totals, product: tot})
  }

  function refreshData() {
    console.log(3);
  }

  function psOnChange(newValue) {
    console.log("psChange", newValue);
    if (!newValue) return;
    var filtr = productInvoice.filter((item) => item.id === newValue.id);
    if (filtr.length > 0) return; // product already in the list dont add
    var newTableData = productInvoice;
    newTableData.splice(0, 0, { qty: 1, ...newValue }); //add new product to the top of invoice

    console.log(newValue, "new product");
    setSaved(false);
    setProductInvoice([...newTableData]);
  }

  const addCharge = async (id) => {
    var filtr = productInvoice.filter((item) => item.id === 10001000);
    if (filtr.length > 0) return; // product already in the list dont add

    //fetch rate
    try {
      const mydata = await dataProvider("get", "/configsheet/" + id, null);

      if (!mydata.data.data[0]) {
        return Swal.fire({
          text: "Failed to apply process. Try again",
          icon: "warning",
          toast: true,
          showConfirmButton: false,
          position: "bottom-end",
          timer: 3000,
        });
      }

      const newValue = {
        id: 10001000,
        price: mydata.data.data[0].amount,
        title: mydata.data.data[0].title,
        description:
          mydata.data.data[0].description +
          ": " +
          moment().format("YYYY-MM-DD hh:mm"),
      };

      var newTableData = productInvoice;
      newTableData.splice(0, 0, { qty: 1, ...newValue }); //add new product to the top of invoice

      console.log(newValue, "new product");
      setSaved(false);
      setProductInvoice([...newTableData]);
      reCalculateTotals();
    } catch (error) {
      console.log(error);

      return Swal.fire({
        text: "Failed to apply process.",
        icon: "warning",
        toast: true,
        showConfirmButton: false,
        position: "bottom-end",
        timer: 3000,
      });
    }
  };

  useEffect(() => {
    console.log(serviceRequest?.id, "serviceRequest");
    console.log(totals.product, "totals.product");

    if (serviceRequest.id) {
      reCalculateTotals();
    }

    if (serviceRequest.id && totals.product) {
      console.log(productInvoice);
      let sum = 0;
      Object.values(productInvoice).map((i) => {
        sum += i.qty * i.price;
      });

      console.log(sum, "sum prodcuts");
      // reCalculateTotals();

      if (sum !== totals.product) {
        Swal.fire({
          text: 'Please press "Save Changes", products total have been adjusted.',
          icon: "info",
          toast: true,
          showConfirmButton: true,
          position: "bottom-end",
        });

        reCalculateTotals();
      }
    }
  }, [serviceRequest]);

  useEffect(() => {
    reCalculateTotals();
  }, [formData]);

  // return serviceRequest?.vin ? (
  return serviceRequest?.id !== undefined ? (
    <div className={classes.root} id="pageTop">
      <form className={classes.formRoot} autoComplete="off">
        <Grid container spacing={3} style={{ paddingTop: 30 }}>
          <Grid item xs={12}>
            <div className={classes.section1}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography
                    gutterBottom
                    variant="h2"
                    style={{ fontSize: "2em" }}
                  >
                    Invoice #{queryID}
                  </Typography>
                  {moment(invoiceData.created_at)
                    .add(5, "hours")
                    .format("YYYY-MM-DD HH:mm")}
                </Grid>
              </Grid>

              <Divider
                style={{
                  marginTop: 15,
                  background: "#f1f1f1",
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.section1}>
              <Grid container alignItems="center">
                <Grid item xs>
                  {/* <ServiceIcon fontSize="large" className={classes.icon}/> */}
                  <img alt="product" src={icon} width={60} />
                  <Typography gutterBottom variant="h5">
                    {serviceRequest?.first_name +
                      " " +
                      serviceRequest?.last_name}
                  </Typography>
                </Grid>
                {/* <Grid item>
            <Typography gutterBottom variant="h6">
              $4.50
            </Typography>
          </Grid> */}
              </Grid>
              <Typography color="textSecondary" variant="body2">
                Enter work hours and select products used for this job.
              </Typography>
              {/* <Divider style={{ marginTop: 15 }} /> */}
            </div>
          </Grid>

          <Grid item sm={6} style={{ textAlign: "left" }}>
            {/* <Typography variant="h6">{serviceRequest.first_name} {serviceRequest.last_name}  </Typography> */}
            <small className={classes.hint}>Registration</small>
            <Typography variant="subtitle1">
              {serviceRequest.registration}{" "}
            </Typography>
            <small className={classes.hint}>Contact</small>
            <Typography variant="subtitle1">
              {serviceRequest.contact}{" "}
            </Typography>
            {/* <Typography variant="subtitle1">{serviceRequest.registration} </Typography> */}
            <small className={classes.hint}>Email</small>
            <Typography variant="subtitle1">{serviceRequest.email} </Typography>
          </Grid>

          <Grid item sm={6} style={{ textAlign: "left" }}>
            <small className={classes.hint}>VIN</small>
            <Typography variant="subtitle1">{serviceRequest.vin} </Typography>
            <small className={classes.hint}>Mileage</small>
            <Typography variant="subtitle1">
              {serviceRequest.mileage}{" "}
            </Typography>
            <small className={classes.hint}>Services</small>
            <Typography variant="subtitle1" color="primary">
              {serviceRequest.service}{" "}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            style={{
              textAlign: "left",
              display: serviceRequest?.type === "product" ? "none" : "",
            }}
          >
            <TextField
              label="Enter work hours"
              name="work_hour"
              id="work_hour"
              variant="outlined"
              className={classes.flow}
              value={formData.work_hour}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  return handleFormChange(e);
                }
              }}
              disabled={disableInvoice() ? false : true}
              required
              type="number"
              inputProps={{ maxLength: 9, min: 0 }}
              autoComplete="off"
            />
            <small className={classes.hint}>
              {/* {formData.rate_hour
                ? formData.rate_hour.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  }) + "/hr"
                : formData.rate_hour} */}
              {formData.rate_hour?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }) + "/hr"}
            </small>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            style={{
              textAlign: "left",
              display: serviceRequest?.type === "product" ? "none" : "",
            }}
          >
            <TextField
              label="Enter rate/hour"
              name="rate_hour"
              id="rate_hour"
              variant="outlined"
              className={classes.flow}
              value={formData.rate_hour}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  return handleFormChange(e);
                }
              }}
              disabled={disableInvoice() ? false : true}
              required
              type="number"
              inputProps={{ maxLength: 9, min: 0, max: 9999999999, step: 1 }}
              autoComplete="off"
            />
          </Grid>

          {/* <Grid item xs={12} md={3} style={{ textAlign: "left" }}>
          <InputLabel id="discount_label">Apply discount</InputLabel>
        <Select
          labelId="discount"
          id="discount"
          name="discount"
          value={formData.discount}
          onChange={handleFormChange}
          label="Apply discount"
        >
          <MenuItem value={0}>
            <em>None</em>
          </MenuItem>
          <MenuItem value={500}>Five Hundred ($500)</MenuItem>
          <MenuItem value={1000}>One Thousand ($1,000)</MenuItem>
          <MenuItem value={1500}>One Thousand Five Hundred ($1,500)</MenuItem>
        </Select>
        
          </Grid> */}

          <Grid item xs={12} md={6}>
            {disableInvoice() ? <ProductSearch callback={psOnChange} /> : ""}
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} style={{ marginTop: 20 }}>
            <DataTable
              queryID={queryID}
              data={productInvoice}
              refresher={refreshData}
              option={option}
              tableUp={mainTableUp}
              active={disableInvoice() ? false : true}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ textAlign: "left", marginTop: 15 }}
          >
            <div>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow key="1">
                      <TableCell align="left">
                        <b>Products Total</b>
                      </TableCell>
                      <TableCell align="left">
                        {totals?.product.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </TableCell>
                    </TableRow>
                    <TableRow key="2">
                      <TableCell align="left">
                        <div>
                          <b>Labour Total</b>
                        </div>
                        <small>
                          {formData.rate_hour
                            ? formData.rate_hour.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              }) + " / hr"
                            : formData.rate_hour}
                        </small>
                      </TableCell>
                      <TableCell align="left">
                        {totals.labour.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </TableCell>
                    </TableRow>
                    <TableRow key="3" style={{ background: "#F5FCD9" }}>
                      <TableCell align="left">
                        <div>
                          <b>Amount Paid</b>
                        </div>
                        {/* <small>
                          {getSettings().rate
                            ? getSettings().rate.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              }) + " / hr"
                            : "Please restart application"}
                        </small> */}
                      </TableCell>
                      <TableCell align="right" style={{ color: "green" }}>
                        <strong>
                          {payment?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </strong>
                      </TableCell>
                    </TableRow>

                    <TableRow key="4" style={{ background: "#eaf8ff" }}>
                      <TableCell align="left">
                        <div>
                          <b>Discount</b>
                        </div>
                        {/* <small>
                          {getSettings().rate
                            ? getSettings().rate.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              }) + " / hr"
                            : "Please restart application"}
                        </small> */}
                      </TableCell>
                      <TableCell align="right" style={{ color: "#222" }}>
                        <strong>
                          {formData.discount?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </strong>
                      </TableCell>
                    </TableRow>

                    <TableRow key="5">
                      <TableCell align="left">
                        <b>Total Due</b>
                      </TableCell>
                      <TableCell align="left">
                        <strong
                          style={{
                            fontSize: "1.2em",
                            color: totals.total === 0 ? "#222" : "#F50359",
                          }}
                        >
                          {totals.total === 0 ? (
                            <div style={{ color: "green" }}>PAID</div>
                          ) : (
                            totals.total.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          )}
                        </strong>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid item xs={12} style={{ marginTop: 9, padding: 5 }}>
                <Paper style={{ padding: 9, backgroundColor: "#f6f6f6" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => history.push("/view-invoices")}
                        style={{ width: "100%", fontWeight: 550 }}
                      >
                        View Invoices
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        size="large"
                        // color="primary"
                        onClick={() =>
                          getPDF_(config.domain + "invoice-pdf/" + queryID)
                        }
                        style={{ width: "100%", fontWeight: 550 }}
                        startIcon={<PictureAsPdf />}
                      >
                        Invoice
                      </Button>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        size="large"
                        // color="primary"
                        onClick={
                          // () => getPDF_(config.domain + "invoice-pdf/" + queryID)
                          () =>
                            printInvoice({
                              ...totals,
                              ...formData,
                              payment,
                              products: productInvoice,
                              id: queryID,
                            })
                        }
                        style={{ width: "100%", fontWeight: 550 }}
                        startIcon={<Print />}
                      >
                        Print
                      </Button>
                    </Grid>

                    {/* <Button
                    variant="contained"
                    size="large"
                    onClick={() => {
                      setIsDialogOpen(true)
                      setReturnData(productInvoice)
                    }
                    }
                    style={{ margin: 9 }}
                    startIcon={<CurrencyExchange />}

                    disabled={profile.permission >= 5 ? false : true}
                  >
                    Return Item
                  </Button> */}

                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        size="large"
                        // color="primary"
                        onClick={() => applyDiscount({ method: "discount" })}
                        style={{
                          width: "100%",
                          fontWeight: 550,
                          display: paid === true ? "none" : "",
                        }}
                        startIcon={<MoneyOff />}
                      >
                        Apply Discount
                      </Button>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        size="large"
                        // color="primary"
                        onClick={() => applyPoints({ method: "redemption" })}
                        style={{
                          width: "100%",
                          fontWeight: 550,
                          display: paid === true ? "none" : "",
                        }}
                        startIcon={<Loyalty />}
                      >
                        Apply Points
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={handleDialogOpen}
                        style={{
                          width: "100%",
                          fontWeight: 550,
                          display: paid === true ? "none" : "",
                        }}
                      >
                        Save & Recal Charges
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  marginTop: 19,
                  padding: 5,
                  display: paid === true ? "none" : "",
                }}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  // style={{ fontSize: "2em" }}
                >
                  Payment methods
                </Typography>

                {/* <Divider  style={{ marginBottom: 9 }} /> */}
                <Paper style={{ padding: 9, backgroundColor: "#f6f6f6" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => paymentDialog({ method: "cash" })}
                        startIcon={<Money />}
                        style={{
                          // margin: 9,
                          fontWeight: 550,
                          backgroundColor: "#98c60b",
                          color: "white",
                          width: "100%",
                        }}
                      >
                        Cash
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => paymentDialog({ method: "credit-card" })}
                        startIcon={<CreditCard />}
                        style={{
                          // margin: 9,
                          fontWeight: 550,
                          backgroundColor: "#ff5919",
                          color: "white",
                          width: "100%",
                        }}
                      >
                        Credit Card
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => paymentDialog({ method: "debit-card" })}
                        startIcon={<CreditCard />}
                        style={{
                          // margin: 9,
                          fontWeight: 550,
                          backgroundColor: "#01baf1",
                          color: "white",
                          width: "100%",
                        }}
                      >
                        Debit Card
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => paymentDialog({ method: "cheque" })}
                        startIcon={<CreditCard />}
                        style={{
                          // margin: 9,
                          fontWeight: 550,
                          backgroundColor: "#d604d7",
                          color: "white",
                          width: "100%",
                        }}
                      >
                        Cheque
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => paymentDialog({ method: "online" })}
                        startIcon={<CreditCard />}
                        style={{
                          // margin: 9,
                          fontWeight: 550,
                          backgroundColor: "#787b84",
                          color: "white",
                          width: "100%",
                        }}
                      >
                        Online
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  marginTop: 19,
                  padding: 5,
                  display: paid === true ? "none" : "",
                }}
              >
                <Paper style={{ padding: 9, backgroundColor: "#f6f6f6" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => addCharge("storage")}
                        startIcon={<GarageOutlined />}
                        style={{
                          // margin: 9,
                          fontWeight: 550,
                          backgroundColor: "#333",
                          color: "white",
                          width: "100%",
                        }}
                      >
                        Add Storage
                      </Button>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <ChargeSwitch
                        on={storageState}
                        callback={updateStorage}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </div>
          </Grid>
        </Grid>

        {/* <Grid container spacing={3}>
         
        </Grid> */}
      </form>
      <AlertDialog
        isOpen={isOpen}
        handleClose={handleDialogClose}
        handleAgree={handleDialogAgree}
        handleCancel={handleDialogCancel}
        title={
          "Scott's Auto -  Invoice for " +
          serviceRequest.first_name +
          " " +
          serviceRequest.last_name
        }
        subtitle="Are you sure you want to continue?"
      />
      <Toast
        isOpen={isToastOpen}
        handleClose={handleToastClose}
        message={toastMessage}
      />

      <AdaptDialog
        title={"Item Return"}
        isOpen={isDialogOpen}
        onClose={handleDialogClose_return}
      >
        <Paper style={{ padding: "2em" }}>
          <form autoComplete="off" onSubmit={handleSaveReturn}>
            {/* <Box
            className={classes.form_Root}
            justifyContent="center" m={3} p={3}
          > */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl
                  required
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormLabel component="legend">
                    Select items being returned
                  </FormLabel>
                  <FormGroup>
                    {returnData.length > 0 &&
                      returnData.map(
                        (i) => {
                          let qty = new Array();
                          for (let x = 1; x <= parseInt(i.qty); x++) {
                            qty.push(x);
                          }
                          return (
                            <>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={i.id.toString()}
                                    onChange={handleReturnFormChange_chk}
                                  />
                                }
                                label={
                                  i.title +
                                  " - " +
                                  i.description +
                                  " - " +
                                  formatter.format(i.price)
                                }
                              />
                              <FormControl>
                                <InputLabel id="demo-simple-select-label">
                                  Qty
                                </InputLabel>
                                <Select
                                  // style={{ width: 120 }}
                                  variant="outlined"
                                  id="demo-simple-select"
                                  // value={null}
                                  label="Qty"
                                  name={i.id.toString()}
                                  // name={i.title + " - " + i.description+"_qty"}
                                  onChange={handleReturnFormSelectItems}
                                >
                                  <MenuItem value={""}>
                                    Select number of items
                                  </MenuItem>
                                  {qty.map((q) => (
                                    <MenuItem value={q}>{q}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              <Divider style={{ marginTop: 10 }} />
                            </>
                          );
                        }

                        // <Grid item xs={12} md={6} key={i.id}>
                        //       {i}

                        // </Grid>)
                      )}
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
                  required={true}
                  fullWidth
                  variant="outlined"
                  onChange={handleReturnFormChange}
                  label="Comments"
                  name="comments"
                />

                <Typography color="textSecondary" variant="body1 ">
                  State the reason for return
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <div style={{ marginTop: "3em", textAlign: "right" }}>
                  <Button
                    type={"submit"}
                    style={{ margin: 5 }}
                    color="primary"
                    variant="contained"
                  >
                    Save
                  </Button>
                  <Button
                    style={{ margin: 5 }}
                    margin="normal"
                    onClick={handleDialogClose_return}
                  >
                    Close
                  </Button>
                </div>
              </Grid>
            </Grid>
            {/* </Box> */}
          </form>
        </Paper>
      </AdaptDialog>
    </div>
  ) : (
    <LinearProgress style={{ marginTop: 60 }} />
  );
}

const ChargeSwitch = ({ on, callback }) => {
  const [isChecked, setIsChecked] = useState(on);

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
    callback(event.target.checked);
  };

  return (
    <FormControlLabel
      control={
        <Switch checked={isChecked} onChange={handleChange} color="primary" />
      }
      label={isChecked ? "Storage Charge On" : "Storage Charge off"}
    />
  );
};
