import React, { forwardRef, useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import Toast from "../components/Toast";
import AlertDialog from "../components/AlertDialog";
import Modal from "@material-ui/core/Modal";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import SelectAll from "@material-ui/icons/SelectAll";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { makeStyles } from "@material-ui/core/styles";

import {
  PersonPinSharp,
  Refresh,
} from "@material-ui/icons";
import { dataProvider } from "../util";
import Swal from "sweetalert2";


const useStyles = makeStyles();
function getModalStyle() {
  const top = 0;
  const left = 0;

  return {
    overflow: "scroll",
    top: `${top}%`,
    left: `${left}%`,
    bottom: 30,
    // transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function DataTable(props) {
  const op = ["booked", "received", "active", "complete", "canceled"];
  const [tableData, setTableData] = useState([]);
  const classes = useStyles();
  const [serviceList, setServiceList] = React.useState([]);
  const [tableTitle, setTableTitle] = React.useState("");
  const [active, setActive] = React.useState(false);
  const [modalScreen, setModalScreen] = React.useState("services");
  const [actionID, setActionID] = React.useState(null);
  const [subject, setSubject] = React.useState({});
  const [service, setService] = React.useState({});
  const [openModal, setOpenModal] = React.useState(false);

  const [modalStyle] = React.useState(getModalStyle);
  // const handleChange = (event) => {
  //   setService({ ...service, [event.target.name]: event.target.checked });
  // };

  useEffect(() => {
    // console.log(props.data, "props");
    setActive(props.active);
    setTableTitle(props.option?.title);
    setTableData(props.data);
  }, [props]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [mcomment, setComment] = useState("");
  const handleComment = (e) => {
    // console.log(e.target.value);
    setComment(e.target.value);
  };

  //Toast ----------------------------------------
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const handleToastClose = () => {
    setIsToastOpen(false);
  };

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    SelectAll: forwardRef((props, ref) => <SelectAll {...props} ref={ref} />),
    Refresh: forwardRef((props, ref) => (
      <Refresh
        // style={{ padding: 3, color: "white", backgroundColor: "green" }}
        {...props}
        ref={ref}
      />
    )),
  };

  //AlertDialog ----------------------------------------
  const [isOpen, setIsOpen] = React.useState(false);
  const handleDialogOpen = () => {
    setIsOpen(true);
  };
  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const handleDialogAgree = () => {
    //   updateStatus(actionID, "canceled");
    // handleDialogCancel();
  };
  const handleDialogCancel = () => {
    setIsOpen(false);
  };
  // -----------------------------------------------------

  const storageSwitch = async(id, state) => {
    try {
      const mydata = await dataProvider("put", "/invoice-storage-state/" + id, { option: state });

    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <MaterialTable
        style={{ fontSize: 14 }}
        title={tableTitle}
        // columns={
        //     profile?.permission > 2?view_admin:view_customer
        // }
        columns={[
          {
            title: "Product", field: "title", editable: "never",
            render: (rowData) => (
              <>
                <div><b>{rowData.title}</b></div>
                <small>{rowData.description}</small></>
            )
          },

          { title: "Price", type: 'currency', field: "price", editable: "never" },
          {
            title: "Qty", type: 'numeric', field: "qty",
            editComponent: props => (
              <input
                type="number"
                min="1"
                value={props.value}
                onChange={e => {
                  if (e.target.value > -1) {
                    return props.onChange(e.target.value);
                  }

                  // return  props.onChange(e.target.value);

                }}
              />
            )
          },

          {
            title: "Total", type: 'currency', field: "price", editable: "never",
            render: (rowData) => (

              (rowData.price * rowData.qty).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })

            )
          },
        ]}
        icons={tableIcons}
        // data={tableData ? tableData : null}
        data={tableData ? tableData : null}
        editable={{
          onRowDelete: active === false ? oldData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                var newTableData = tableData.filter(item => item.id !== oldData.id);
                setTableData([...newTableData]);
                props.tableUp(newTableData)

                //turn off storage
                console.log(tableData, "delete")


                if (oldData.id === 10001000 && props.queryID) {
                 storageSwitch(props.queryID, 0);
                }
                resolve()
              }, 1000)
            }) : null,

          onRowUpdate: active === false ? (newData, oldData) => {
             console.log(newData, "new on up")
             console.log(oldData, "oldData")

             if ( (newData.qty && newData.stock) &&  newData.qty > newData.stock) {
              Swal.fire({
                icon: "warning",
                text: newData.title + " has only (" + newData.stock + ") in stock",
                toast: true,
                // showConfirmButton: false,
                timer: 2500,
                // position: 'bottom-end',
              });
              return new Promise((resolve, reject) => reject());
             }
            if (newData.qty < 1) newData.qty = 1; //no zero of blank values, if so do delete
            var indx = tableData.indexOf(oldData); //get location of old element
            var newTableData = tableData.filter(item => item.id !== oldData.id); //filter out old element
            newTableData.splice(indx, 0, newData); //re-add updated new element
            // arr.push
            setTableData([...newTableData]);

            props.tableUp(newTableData)
            // setTableData([newData, ...newTableData]);
            // setTableData()
            return new Promise((resolve, reject) => resolve());
          } : null
          // new Promise((resolve, reject) => {
          //   putService(newData)
          //     .then((res) => {
          //       setToastMessage(res.data.message);
          //       setIsToastOpen(true);
          //       props.refresher();
          //       resolve();
          //     })
          //     .catch((error) => {
          //       setToastMessage(error.message);
          //       setIsToastOpen(true);
          //       reject();
          //     });
          //     }),
        }
        }
        options={{
          defaultExpanded: true,
          pageSize: 10,
          pageSizeOptions: [10, 20, 50, 100, 500],
          sorting: true,
          search: false,
          exportButton: false,
          rowStyle: (rowData) => {
            // console.log(rowData)
            if (rowData.tableData.id % 2 === 0) {
              return { backgroundColor: "#fcf4f6" };
            }
            // console.log(rowData);
          },
        }}
      // actions={[
      //   {
      //     tooltip: "Refresh",
      //     icon: tableIcons.Refresh,
      //     position: "toolbar",
      //     onClick: () => {
      //       props.refresher();
      //     },
      //   },
      // ]}
      />

      <Toast
        isOpen={isToastOpen}
        handleClose={handleToastClose}
        message={toastMessage}
      />
      <AlertDialog
        isOpen={isOpen}
        handleClose={handleDialogClose}
        handleAgree={handleDialogAgree}
        handleCancel={handleDialogCancel}
        title={"Cancel Job " + actionID}
        subtitle="Are you sure you want to cancel this job?"
      />
    </>
  );
}
