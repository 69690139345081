import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import MaskText from "../components/MaskText";
import Toast from "../components/Toast";
import { useHistory, useLocation } from "react-router-dom";
import {
  TextField,
  Grid,
  Typography,
  Divider,
  Button,
  LinearProgress,
  Chip,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import service_icon from "../car-service.png";
import icon from "../invoice.png";
import {
  dataProvider,
  getSettings,
  config,
  searchGlobalCustomer,
} from "../util";
import AlertDialog from "../components/AlertDialog";
// import * as Scroll from 'react-scroll';
import { scroller } from "react-scroll";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DataTable from "../components/DataTable";
import Inventory from "@mui/icons-material/Inventory";
import { Badge } from "@mui/material";
import ProductSearch from "../components/ProductSearch";
import AddCustomer from "../features/AddCustomer";
import { Person } from "@material-ui/icons";
import { Money } from "@mui/icons-material";
import Swal from "sweetalert2";

// var scroll = animateScroll;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 30,
    // background: "#f3f3f3"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  formRoot: {
    "& > *": {
      //   margin: theme.spacing(1),
      //   minWidth: '100%',
      //   background: 'red'
    },
  },
  flow: {
    width: "100%",
  },
  icon: {
    color: "red",
  },
  section1: {
    marginTop: 20,
  },
  subTitle: {
    color: "#0635C9",
    textAlign: "left",
    fontSize: "1.2em",
  },
  hint: {
    color: "#aaa",
  },
}));



export default function MakeSale() {
  let history = useHistory();
  const classes = useStyles();
  const [toggle, setToggle] = React.useState(false)
  const [profile, setProfile] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    rate_hour: 0,
    work_hour: 0,
  });
  const [totals, setTotals] = React.useState({
    product: 0,
    labour: 0,
    total: 0,
  });
  const [fetchedData, setFetchedData] = React.useState({ type: "product" });
  const [products, setProducts] = React.useState([]);
  const [customerSearch, setCustomerSearch] = React.useState([]);
  const [productValue, setProductValue] = React.useState([]);
  const [productInvoice, setProductInvoice] = React.useState([]);
  const [option, setOption] = useState({ title: "Products", id: "active" });

  //AlertDialog ----------------------------------------
  const [isOpen, setIsOpen] = React.useState(false);
  const handleDialogOpen = () => {
    setIsOpen(true);
  };
  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const scrolly = (ele) => {
    scroller.scrollTo(ele, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -90,
    });
  };
  const handleDialogAgree = () => {
    setIsOpen(false);
    // if (!formData.work_hour || formData.work_hour.length < 1) {
    //   doToast("Enter work hours");
    //   scrolly("work_hour");
    //   return false;
    // }

    // if (!formData.rate_hour || formData.rate_hour.length < 1) {
    //   doToast("Enter rate/hour");
    //   scrolly("rate_hour");
    //   return false;
    // }

    //postCustomer(customer)

    if(totals.total <= 0){
      return    Swal.fire({
        icon: 'warning',
        text: 'Please add some products first.',
        toast: true,
        // showConfirmButton: false,
        timer: 3500,
        // position: 'bottom-end',
      })
    }

    setLoading(true);
    dataProvider("post", "/invoice-product", {
      fetchedData,
      formData,
      productInvoice,
      totals,
    })
      .then((res) => {
        setToastMessage(res.data.message);
        setIsToastOpen(true);
        setLoading(false);
        setTimeout(() => {
          history.push("view-invoice/?id=" + res.data.data.invoice_id);
        }, 1500);
      })
      .catch((error) => {
        console.log(error, "post");
        setToastMessage(error.message);
        setIsToastOpen(true);
        setLoading(false);

        //  window.location = "/login";
      });
  };

  const reset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    setFormData({});
  };

  const doToast = (message) => {
    setToastMessage(message);
    setIsToastOpen(true);
  };
  const handleDialogCancel = () => {
    // window.alert('cancel');
    setIsOpen(false);
  };
  // -----------------------------------------------------

  //Toast ----------------------------------------
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  // const handleToastOpen = () => {
  //   setIsToastOpen(true);
  // };
  const handleToastClose = () => {
    setIsToastOpen(false);
  };
  // -----------------------------------------------------
  //Update work hours Labour Total
  const handleFormChange = (event) => {
    // if (event.target.name === "work_hour") {
    //   updateWorkHour(event.target.value);
    // }
    // if (event.target.name === "rate_hour") {
    //   updateRateHour(event.target.value);
    // }
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const updateWorkHour = (hours) => {
    if (hours < 0) return false;
    var labour_total = formData.rate_hour * hours;
    /* set product total */
    var product_total = 0;
    productInvoice.forEach((element) => {
      product_total += element.qty * element.price;
    });
    var all_total = labour_total + product_total;
    setTotals({
      ...totals,
      product: product_total,
      total: all_total,
      labour: labour_total,
    });
    setFormData({ ...formData, work_hour: hours });

  };
  const updateRateHour = (rate) => {
    // if (rate < 0) return false;
    // var labour_total = formData.work_hour * rate;
    var labour_total = 0;
    /* set product total */
    var product_total = 0;
    productInvoice.forEach((element) => {
      product_total += element.qty * element.price;
    });
    var all_total = labour_total + product_total;
    setTotals({
      ...totals,
      product: product_total,
      total: all_total,
      labour: labour_total,
    });
    setFormData({ ...formData, rate_hour: rate });

  };

  const queryID = useQuery().get("id");
  useEffect(() => {
    scrolly("pageTop");
    getJob();
    loadProducts();
    //set default rate
    // initValues();
  }, []);

  useEffect(() => {
    getJob();
 
  }, [queryID]);


  useEffect(() => {
    /* set product total */
    var product_total = 0;
    productInvoice.forEach((element) => {
      product_total += element.qty * element.price;
    });
    var all_total = totals?.labour + product_total;
    setTotals({ ...totals, product: product_total, total: all_total });
    // setTotals({...totals})
  }, [productInvoice]);

  /**
   * Get Job details
   * @returns null
   */

  function getJob() {
    console.log('getJob', queryID)
    if (!queryID) return;
    if (queryID === null) return;

    if (queryID === 'default') {
      return setFetchedData(
        {
          id: 0,
          first_name: "-",
          last_name: "-",
          contact: "-",
          email: "-",
          vin: "-",
          registration: "-",
          type: "product"
        }
      )
    }

    dataProvider("get", "/customerByID/" + queryID, {})
      .then((res) => {
        // console.log(res," RES")
        // if (res.message === "Network Error") throw new Error(res.message);

        if (res.data) {
          res.data.data.type = "product";
          setFetchedData(res.data.data);
        } else {
          alert("No record found " + queryID);
          //   history.goBack();
        }
      })
      .catch((error) => {
        console.error(error, "post");
        setToastMessage(error);
        setIsToastOpen(true);
        //  window.location = "/login";
      });
  }


  /**
   * Get all Products
   * @returns object
   */
  async function loadProducts() {
    try {
      const mydata = await dataProvider("get", "/products", []);
      setProducts(mydata.data);
    } catch (error) {
      console.log(error);
      setToastMessage(error.message);
      setIsToastOpen(true);
    }
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const populateCustomer = async () => {

    // if(id && id.length < 2) return false;
    let id = null;

    if (profile && profile["contact"]?.length > 1) {
      id = profile["contact"];
    } else {
      return false;
    }
    // if (qvin && qvin?.length > 2) vin = qvin;
    // if (vin?.length < 3) return false;

    try {
      const data = await searchGlobalCustomer(id);
      let mycust = data.data;
      // console.log(mycust, "mycust");

      // if (mycust["active"] === 0) {
      //   setToastMessage("Customer is Blocked. Please contact Admin.");
      //   setIsToastOpen(true);
      //   resetCustomer();
      //   return false;
      // }

      // setCustomer(mycust);
      // console.log(mycust, "setcust");

      if (typeof mycust === "undefined") {
        // setToastMessage("Customer NOT FOUND.");
        // setIsToastOpen(true);
        // resetCustomer();
        setCustomerSearch([]);
        return false;
      }
      // console.log(data.data[0])
      if (mycust[0] && mycust[0]["First name"]) {
        // setCustomer(data.data[0]);
        console.log(mycust, "setcust");
        setCustomerSearch(mycust);
        // Array.from(document.querySelectorAll("input")).forEach((input) => {
        //   if (input.id) {
        //     input.value = mycust[input.name];
        //   }
        // });
      } else {
        setCustomerSearch([]);
      }
    } catch (error) {
      console.error(error);
    }

    //
  };

  const updateProfileState = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
    // if (e.target.name === "contact") {

    //   if (e.target.value?.length > 0) {
    //     console.log(e.target.value +e.target.value?.length)
    //     populateCustomer()
    //     // return false;

    //   }
    // }

  };

  useEffect(() => {

    // if(profile?.contact && profile.contact.length > 1){
    // console.log(profile.contact.length )
    populateCustomer()
    // }
  }, [profile])

  function mainTableUp(v) {
    if (v[0]?.qty < 1) return false;
    /* set product total */
    var product_total = 0;
    productInvoice.forEach((element) => {
      console.log(element, "ele");
      product_total += element.qty * element.price;
    });
    var all_total = totals?.labour + product_total;
    setTotals({ ...totals, product: product_total, total: all_total });
    setProductInvoice([...v]);
    // setTotals({...totals, product: tot})
  }




  function psOnChange(newValue) {
    if (!newValue) return;
    var filtr = productInvoice.filter(
      (item) => item.id === newValue.id
    );
    if (filtr.length > 0) return;
    var newTableData = productInvoice;
    newTableData.splice(0, 0, { qty: 1, ...newValue });
    setProductInvoice([...newTableData]);
    setProductValue(productValue);
  }
  return queryID ? (
    // return fetchedData.id ? (
    <div className={classes.root} id="pageTop">
      <form className={classes.formRoot} autoComplete="off">
        <Grid container spacing={3} style={{ paddingTop: 30 }}>
          <Grid item xs={12} style={{ textAlign: "left" }}>
            {/* <TextField
              className={classes.flow}
              variant="outlined"
              label="Customer Search"
              placeholder="Search by registration/contact number/customer-id/"
              value={profile.contact}
              autoComplete="off"
              InputLabelProps={{
                shrink: true,
              }}
              name="contact"
              onChange={updateProfileState}
            /> */}

            <Autocomplete
              id="customer_search"
              //   onChange={productUpdate}
              onChange={(event, newValue) => {
                // console.log(newValue);

                setTimeout(() => {
                  //history.push("/make-sale?id=" + newValue.id);
                  window.location.href = "/make-sale?id=" + newValue.id;
                }, 100);

                // if (!newValue) return;
                // var filtr = productInvoice.filter(
                //   (item) => item.id === newValue.id
                // );
                // if (filtr.length > 0) return;
                // var newTableData = productInvoice;
                // newTableData.splice(0, 0, { qty: 1, ...newValue });
                // setProductInvoice([...newTableData]);
                // setProductValue(productValue);
              }}
              //   value={productValue}
              options={customerSearch}
              getOptionLabel={(option) => {
                return option["First name"];
              }}
              renderOption={(option) => (
                <React.Fragment>
                  <div>{`${option["First name"]} ${option["Last name"]} `}</div>
                  &nbsp;
                  <div style={{ color: "#666" }}>
                    <small><Chip label={option["Registration Number"]} /> {option["VIN"]}</small>
                  </div>
                </React.Fragment>
              )}
              //   style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Find customer"
                  variant="outlined"
                  placeholder="Search by registration, contact number,customer-id, VIN"
                  value={profile.contact}
                  name="contact"
                  onChange={updateProfileState}
                />
              )}
            />
          </Grid>
          {/* <Grid item sm={1}>
            <Button
              size="large"
              color="primary"
              variant="contained"
            // onClick={populateCustomer}
            >
              Search
            </Button>
          </Grid> */}
          <Grid item xs={12}>
            <div className={classes.section1}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography
                    gutterBottom
                    variant="h2"
                    style={{ fontSize: "2em" }}
                  >
                    <b>Create</b> Product Invoice
                  </Typography>
                </Grid>
                {/* <Grid item>1
                  <Typography gutterBottom variant="h6">
                    $4.50
                  </Typography>
                </Grid> */}
              </Grid>

              <Divider
                style={{
                  marginTop: 15,
                  background: "#f1f1f1",
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.section1}>
              <Grid container alignItems="center">
                <Grid item xs>
                  {/* <ServiceIcon fontSize="large" className={classes.icon}/> */}
                  <img alt="product" src={icon} width={60} />
                  <Typography gutterBottom variant="h5">
                    {fetchedData?.first_name + " " + fetchedData?.last_name}
                  </Typography>
                </Grid>
                {/* <Grid item>
            <Typography gutterBottom variant="h6">
              $4.50
            </Typography>
          </Grid> */}
              </Grid>
              <Typography color="textSecondary" variant="body2">
                Select products for this sale.
              </Typography>
              {/* <Divider style={{ marginTop: 15 }} /> */}
            </div>
          </Grid>

          <Grid item sm={6} style={{ textAlign: "left" }}>
            <small className={classes.hint}>Contact</small>
            <Typography variant="subtitle1">{fetchedData.contact} </Typography>
            {/* <Typography variant="subtitle1">{fetchedData.registration} </Typography> */}
            <small className={classes.hint}>Email</small>
            <Typography variant="subtitle1">{fetchedData.email} </Typography>
          </Grid>

          {/* 
          <Grid item xs={12} md={3} style={{ textAlign: "left" }}>
            <TextField
              label="Enter work hours"
              name="work_hour"
              id="work_hour"
              variant="outlined"
              className={classes.flow}
              value={formData.work_hour}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  return handleFormChange(e);
                }
              }}
              required
              // InputProps={{
              //   inputComponent: MaskText,
              // }}
              type="number"
              // onInput={(e) => {
              //   e.target.value = parseFloat(e.target.value).toFixed(2)
              // }}
              inputProps={{ maxLength: 9, min: 0 }}
              autoComplete="off"
            />
            <small className={classes.hint}>
              {getSettings().rate
                ? getSettings().rate.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  }) + "/hr"
                : "Please restart application"}
            </small>
          </Grid> */}

          <Grid item xs={12} md={6}>

            <ProductSearch callback={psOnChange} />
            {/* <Autocomplete
              id="product"
              //   onChange={productUpdate}
              onChange={(event, newValue) => {
                if (!newValue) return;
                var filtr = productInvoice.filter(
                  (item) => item.id === newValue.id
                );
                if (filtr.length > 0) return;
                var newTableData = productInvoice;
                newTableData.splice(0, 0, { qty: 1, ...newValue });
                setProductInvoice([...newTableData]);
                setProductValue(productValue);
              }}
              //   value={productValue}
              options={products}
              getOptionLabel={(option) => {
                return (
                  option.title +
                  " - " + "[" + option.stock + "]" +
                  " - " +
                  option.price.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                );
              }}
              renderOption={(option) => (
                <React.Fragment>
                  <div>{option.title}</div>&nbsp;
                  <small style={{ color: "#999", float: "right" }}>
                    ({option.description})
                  </small>
                  &nbsp;-&nbsp;
                  <small
                    style={{
                      color: "#1a6ba8",
                      float: "right",
                      fontWeight: 700,
                    }}
                  >
                    {option.price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </small>
                  &nbsp;-&nbsp;

                  <Badge badgeContent={option.stock ? option.stock : '0'} color="info" >
                    <Inventory color="action" style={{ width: 18 }} />
                  </Badge>


                </React.Fragment>
              )}
              //   style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose products"
                  variant="outlined"
                />
              )}
            /> */}
          </Grid>
        </Grid>

        {/* <Grid item xs={12} style={{ marginTop: 20 }}>
          <DataTable
            data={productInvoice}
            refresher={refreshData}
            option={option}
            tableUp={mainTableUp}
          />
        </Grid>
        <Grid item sm={12} style={{ textAlign: "left", marginTop: 15 }}>
 */}

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} style={{ marginTop: 20 }}>
            <DataTable
              data={productInvoice}
              // refresher={refreshData}
              option={option}
              tableUp={mainTableUp}
              active={false}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ textAlign: "left", marginTop: 15 }}
          >
            <div>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow key="1">
                      <TableCell align="left">
                        <b>Products Total</b>
                      </TableCell>
                      <TableCell align="left">
                        {totals.product.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </TableCell>
                    </TableRow>
                    <TableRow key="2">
                      <TableCell align="left">
                        <div>
                          <b>Labour Total</b>
                        </div>
                        <small>
                          {formData.rate_hour
                            ? formData.rate_hour.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            }) + " / hr"
                            : formData.rate_hour}
                        </small>
                      </TableCell>
                      <TableCell align="left">
                        {totals.labour.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </TableCell>
                    </TableRow>
                    <TableRow key="3" style={{ background: "#E8F4FD" }}>
                      <TableCell align="left">
                        <b>Total</b>
                      </TableCell>
                      <TableCell align="left">
                        <strong>
                          {totals.total.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </strong>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <LinearProgress
                style={{ visibility: loading ? "visible" : "hidden" }}
              />
              {/* </Grid> */}

              {/* <Grid container spacing={3}>
          <Grid item xs={12} style={{ marginTop: 20 }}> */}

              <Grid item xs={12} style={{ marginTop: 9, padding: 5 }}>
                <Paper style={{ padding: 3 }}>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => history.goBack()}
                    style={{ margin: 9 }}
                  >
                    Go Back
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={handleDialogOpen}
                    disabled={loading ? true : false}
                    style={{ margin: 9 }}
                  // {(loading === true)?disabled:''}
                  //   type="submit"
                  >
                    Save
                  </Button>
                </Paper>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </form>
      <AlertDialog
        isOpen={isOpen}
        handleClose={handleDialogClose}
        handleAgree={handleDialogAgree}
        handleCancel={handleDialogCancel}
        title={
          "Scott's Auto -  Invoice"
        }
        // title={
        //   "Scott's Auto -  Invoice for " +
        //   fetchedData.first_name +
        //   " " +
        //   fetchedData.last_name
        // }
        subtitle="Are you sure you want to continue?"
      />
      <Toast
        isOpen={isToastOpen}
        handleClose={handleToastClose}
        message={toastMessage}
      />
    </div>
  ) : (
    // <LinearProgress style={{ marginTop: 60 }} />

    <>

      <Grid container style={{ marginTop: 60, padding: 10 }}>
        <Grid item xs={12} style={{ textAlign: "left" }}>
          {/* <TextField
    className={classes.flow}
    variant="outlined"
    label="Customer Search"
    placeholder="Search by registration/contact number/customer-id/"
    value={profile.contact}
    autoComplete="off"
    InputLabelProps={{
      shrink: true,
    }}
    name="contact"
    onChange={updateProfileState}
  /> */}

          <Autocomplete
            id="customer_search"
            //   onChange={productUpdate}
            filterOptions={x => x}
            onChange={(event, newValue) => {
              //console.log(newValue);


              //history.push("/make-sale?id=" + newValue.id);
              window.location.href = "/make-sale?id=" + newValue.id;

              // if (!newValue) return;
              // var filtr = productInvoice.filter(
              //   (item) => item.id === newValue.id
              // );
              // if (filtr.length > 0) return;
              // var newTableData = productInvoice;
              // newTableData.splice(0, 0, { qty: 1, ...newValue });
              // setProductInvoice([...newTableData]);
              // setProductValue(productValue);
            }}
            //   value={productValue}
            options={customerSearch}
            getOptionLabel={(option) => {
              return option["First name"];
            }}
            renderOption={(option) => (
              <React.Fragment>
                <div>{`${option["First name"]} ${option["Last name"]} `}</div>
                &nbsp;
                <div style={{ color: "#666" }}>
                  <small><Chip label={option["Registration Number"]} /> {option["VIN"]}</small>
                </div>
              </React.Fragment>
            )}
            //   style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Find customer"
                variant="outlined"
                placeholder="Search by registration, contact number,customer-id, VIN"
                value={profile.contact}
                name="contact"
                onChange={updateProfileState}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center", padding: 20 }}>
          <Button onClick={() => setToggle(prev => !prev)} variant="outlined" size="large" startIcon={<Person />}>Add Customer</Button>
        </Grid>
        
        {/* <Grid item xs={12} md={6}  style={{ textAlign: "center", padding: 20 }}>
          <Button onClick={() => setToggle(prev => !prev)} variant="contained" color="primary" size="large" startIcon={<Money />}>Walk-in Customer</Button>
        </Grid> */}
      </Grid>
      <AddCustomer opener={toggle} closer={setToggle} />
    </>
  );
}
