export const roles = [
    {
        title: " Customer Rep.",
        permission: 2,
        color: '#666'
    }, {
        title: "Service Agent",
        permission: 3,
        color: '#8EC2E9'
    }, {
        title: "Admin",
        permission: 4,
        color: '#165A90'
    },{
        title: "Super Admin",
        permission: 5,
        color: '#E82430'
    },

]