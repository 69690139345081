import React, { forwardRef, useState, useEffect } from "react";

import { Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { dataProvider, getProfile } from "../util";
import Toast from "../components/Toast";
import AlertDialog from "../components/AlertDialog";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteRounded from "@material-ui/icons/DeleteRounded";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import SelectAll from "@material-ui/icons/SelectAll";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Refresh } from "@material-ui/icons";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
    paddingTop: 60,
    // padding: 30,
    // background: "#f3f3f3"
  },
  formRoot: {
    "& > *": {
      //   margin: theme.spacing(1),
      //   minWidth: '100%',
      //   background: 'red'
    },
  },
  flow: {
    width: "100%",
  },
  icon: {
    color: "red",
  },
  section1: {
    marginTop: 20,
  },
  subTitle: {
    color: "#0635C9",
    textAlign: "left",
    fontSize: "1.2em",
  },
}));

export default function ViewSysLog() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");

  const handleToastClose = () => {
    setIsToastOpen(false);
  };

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteRounded {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    SelectAll: forwardRef((props, ref) => <SelectAll {...props} ref={ref} />),
    Refresh: forwardRef((props, ref) => (
      <Refresh
        // style={{ padding: 3, color: "white", backgroundColor: "green" }}
        {...props}
        ref={ref}
      />
    )),
  };



  const [profile, setProfile] = React.useState({ username: "", permission: 0 });
  useEffect(() => {
    loadProfile();
    loadData();
  }, []);

  async function loadProfile() {
    const profile = await getProfile();
    setProfile(profile);
  }

  async function loadData() {
    try {
      const mydata = await dataProvider("get", "/sys-log?category=service", []);
      setData(mydata.data.data);
    } catch (error) {
      console.log(error);
      setToastMessage(error.message);
      setIsToastOpen(true);
    }
  }


  const view_admin = [
    { title: "ID", field: "id", editable: false },
    {
      title: "Subject",
      field: "subject",
      editable: false
    },
    { title: "Log", field: "predicate", editable: false  },
    { title: "Date", field: "created_at", editable: false },
    // { title: "Category", field: "category", editable: false },

  ];

  return (
    <div className={classes.root}>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaterialTable
            style={{ fontSize: 14 }}
            title="Activity Log"
            columns={view_admin}
            icons={tableIcons}
            data={data ? data : null}

            options={{
              pageSize: 10,
              pageSizeOptions: [10, 20, 50, 100, 500],
              sorting: true,
              search: true,
              exportButton: true,
              rowStyle: (rowData) => {
                // console.log(rowData)
                if (rowData.tableData.id % 2 === 0) {
                  // return { backgroundColor: "#fceffc" };
                  return { backgroundColor: "#eaf9f7" };
                }
              },
            }}
            actions={[
              {
                tooltip: "Refresh",
                icon: tableIcons.Refresh,
                position: "toolbar",
                onClick: () => {
                  loadData();
                },
                // onClick: (evt, data) => {(doAll)?setOpenD(true):setShowSnack(true)}
                // onClick: (evt, data) => alert('Confirm delivery for ' + data.length + ' packages')
              }
            ]}
          />
        </Grid>
      </Grid>
  
      <Toast
        isOpen={isToastOpen}
        handleClose={handleToastClose}
        message={toastMessage}
      />


    </div>
  );
}
