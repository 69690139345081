import React, { forwardRef, useState, useEffect } from "react";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  dataProvider,
  putCustomer,
  getProfile,
  getPDF,
  isIOSDevice,
  getPDF_,
  config,
  customerStatusChange,
  saveCustomerDetail,
  copyElementText,
} from "../util";
import Toast from "../components/Toast";

import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import SelectAll from "@material-ui/icons/SelectAll";
import ViewColumn from "@material-ui/icons/ViewColumn";
import {
  Refresh,
  BuildOutlined,
  Block,
  CheckBox,
  ContactsOutlined,
} from "@material-ui/icons";
import { TextField, Button } from "@material-ui/core";
import Swal from "sweetalert2";
import { Chip } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
    paddingTop: 60,
    // padding: 30,
    // background: "#f3f3f3"
  },
  formRoot: {
    "& > *": {
      //   margin: theme.spacing(1),
      //   minWidth: '100%',
      //   background: 'red'
    },
  },
  flow: {
    width: "100%",
  },
  icon: {
    color: "red",
  },
  section1: {
    marginTop: 20,
  },
  subTitle: {
    color: "#0635C9",
    textAlign: "left",
    fontSize: "1.2em",
  },
}));

export default function ViewCustomers() {

  const tableRef = React.createRef();
  const classes = useStyles();
  // {
  //   contact: "",
  //   created_at: "",
  //   email: "",
  //   first_name: "",
  //   id: "",
  //   last_name: "",
  //   mileage: "",
  //   registration: "",
  //   service: "[]",
  //   vin: "",
  // }
  const [data, setData] = useState([]);

  //Toast ----------------------------------------
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [mycust, setMyCust] = React.useState({});
  // const handleToastOpen = () => {
  //   setIsToastOpen(true);
  // };
  const handleToastClose = () => {
    setIsToastOpen(false);
  };
  // -----------------------------------------------------

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    SelectAll: forwardRef((props, ref) => <SelectAll {...props} ref={ref} />),
    Refresh: forwardRef((props, ref) => (
      <Refresh
        // style={{ padding: 3, color: "white", backgroundColor: "green" }}
        {...props}
        ref={ref}
      />
    )),
  };

  //   useEffect(() => {
  //       console.log('e')
  //     loadData();
  //   }, []);

  const [profile, setProfile] = React.useState({ username: "", permission: 0 });
  useEffect(() => {
    loadProfile();
    loadData();
  }, []);

  async function loadProfile() {
    const profile = await getProfile();
    setProfile(profile);
  }

  const handleCustomerChange = (event) => {
    console.log((event.target.name = "  --  " + event.target.value));

    // setCustomer({ [event.target.name]: event.target.value });
    setMyCust({ [event.target.name]: event.target.value });
  };

  useEffect(() => {
    console.log(mycust, "mycust");
  }, [mycust]);

  const saveCustDetails = (id) => {
    // return alert(id)
    console.log(mycust);
    //  try {
    saveCustomerDetail({
      id: id,
      note: mycust.note,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
      });
    // console.log(res)
    //    } catch (error) {
    //     Swal.fire('Save failed', '', 'error');
    //     console.error(error)
    //    }
  };

  async function loadData() {
    try {
      const mydata = await dataProvider("get", "/customers", []);
      setData(mydata.data);
    } catch (error) {
      console.log(error);
      setToastMessage(error.message);
      setIsToastOpen(true);
    }
  }

  //   const ref = React.useRef(0);
  //     React.useEffect(() => {
  //         ref.current += 1;
  //     });

  //   const vin_ref = useRef(0);

  const view_customer = [
    { title: "ID", field: "id", editable: "never" },
    {
      title: "First Name",
      field: "first_name",
    },
    { title: "Last Name", field: "last_name" },

    {
      title: "VIN",
      field: "vin",
      editable: "never",
      render: (rowData) => (
        <div
          onClick={() => {
            copyElementText(rowData.vin);
          }}
        >
          {rowData.vin}{" "}
        </div>
      ),
    },
  ];
  const view_admin = [
    
    {
      title: "Registration", field: "registration", width: 150, render: row => (
        <span onClick={() => {
          copyElementText(row.registration);
        }} style={{ border: '1px solid #0c4bd3', borderRadius: 4, color: '#0c4bd3', background: 'white', padding: 3, width: 'auto' }}>{row.registration}</span>
      )
    },
    {
      title: "First Name",
      field: "first_name",
      width: 150,
    },
    { title: "Last Name", field: "last_name", width: 150 },
    { title: "VIN", field: "vin",
    render: row => (
      <Chip variant="outlined" onClick={() => {
        copyElementText(row.vin);
      }} label={row.vin} />
    ) },

    // {
    //   title: "VIN",
    //   field: "vin",
    //   width: 150,
    //   render: (rowData) => (
    //     <div
    //       style={{ width: 220 }}
    //       onClick={() => {
    //         copyElementText(rowData.vin);
    //       }}
    //     >
    //       {rowData.vin}{" "}
    //     </div>
    //   ),
    // },
    { title: "ID", field: "id", editable: "never", width: 150 },
    {
      title: "Email",
      field: "email",
      width: 250,
      render: (rowData) => (
        <a
          target="new_"
          style={{ color: "#047bea" }}
          href={"mailto:" + rowData.email}
        >
          {rowData.email}{" "}
        </a>
      ),
    },
    {
      title: "Contact",
      field: "contact",
      type: "numeric",
      width: 150,
      render: (rowData) => (
        <a
          target="new_"
          style={{ color: "#047bea" }}
          href={"tel:" + rowData.contact}
        >
          {rowData.contact}{" "}
        </a>
      ),
    },

    { title: "Entry Date", field: "created_at", editable: "never", width: 150 },
    { title: "Points", field: "current_points", editable: "never", width: 150 },
    { title: "Discount (%)", type: "numeric", field: "discount", width: 150 },
    {
      title: "Note",
      field: "note",
      editComponent: (props) => (
        <>
          <TextField
            label="Note"
            name="note"
            variant="outlined"
            className={classes.flow}
            onChange={(e) => props.onChange(e.target.value)}
            rows={3}
            style={{
              width: 300,
            }}
            value={props.value}
            autoComplete="off"
            inputProps={{
              autoComplete: "off",
              form: {
                autoComplete: "off",
              },
              maxLength: 500,
            }}
            multiline
          />
          {/* <Button onClick={(e)=>{
          
          console.log(props);
        }} variant="contained">Save Note</Button> */}
        </>

      ),
    },
  ];

  return (
    <div className={classes.root}>
      {/* <form className={classes.formRoot} onSubmit={handleSubmit}> */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaterialTable
            ref={tableRef}
            style={{ fontSize: 14 }}
            title="Customers"
            columns={profile?.permission > 2 ? view_admin : view_customer}
            // detailPanel={[
            //   {
            //     // icon: 'account_circle',
            //     tooltip: "Show Surname",
            //     render: (rowData) => {
            //       return (
            //         <div>
            //           <div
            //             style={{
            //               // fontSize: 100,
            //               // textAlign: 'center',
            //               // color: 'white',
            //               // backgroundColor: '#E53935',
            //               padding: 10,
            //             }}
            //           >
            //             <form autoComplete="off" >
            //             <TextField
            //               label="Note"
            //               name="note"
            //               variant="outlined"
            //               className={classes.flow}
            //               onChange={handleCustomerChange}
            //               rows={3}
            //               value={rowData.note}
            //               autoComplete="off"
            //               inputProps={{
            //                 autoComplete: "off",
            //                 form: {
            //                   autoComplete: "off",
            //                 },
            //                 maxLength: 500,
            //               }}
            //               multiline
            //             /></form>
            //           </div>
            //           <div
            //             style={{
            //               // fontSize: 100,
            //               textAlign: 'right',
            //               // color: 'white',
            //               // backgroundColor: '#E53935',
            //               padding: 10,
            //             }}
            //           >
            //             <Button color="primary" onClick={()=>saveCustDetails(rowData.id)} variant="contained">
            //               Save Note
            //             </Button>
            //           </div>
            //         </div>
            //       );
            //     },
            //   },
            // ]}
            icons={tableIcons}
            data={data ? data : null}
            editable={
              profile?.permission > 2
                ? {
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      putCustomer(newData)
                        .then((res) => {
                          setToastMessage(res.data.message);
                          setIsToastOpen(true);
                          loadData();
                          resolve();
                        })
                        .catch((error) => {
                          console.error(error)
                          setToastMessage(error.response.data.message);
                          setIsToastOpen(true);
                          reject();
                        });
                    }),
                }
                : null
            }
            options={{
              // fixedColumns:{
              //   left:1
              // },
              
              pageSize: 10,
              pageSizeOptions: [10, 20, 50, 100, 200],
              sorting: true,
              search: true,
              exportButton: true,
              rowStyle: (rowData) => {
                // console.log(rowData)
                if (rowData.active === 0) {
                  // return { backgroundColor: "#fceffc" };
                  return { backgroundColor: "#f97a9c" };
                }
                if (rowData.tableData.id % 2 === 0) {
                  // return { backgroundColor: "#fceffc" };
                  return { backgroundColor: "#eaf9f7" };
                }
              },
            }}
            actions={[
              {
                tooltip: "Refresh",
                icon: tableIcons.Refresh,
                position: "toolbar",
                onClick: () => {
                  loadData();
                },
                // onClick: (evt, data) => {(doAll)?setOpenD(true):setShowSnack(true)}
                // onClick: (evt, data) => alert('Confirm delivery for ' + data.length + ' packages')
              },

              (rowData) =>
                profile?.permission > 3
                  ? {
                    icon: BuildOutlined,
                    tooltip: "Service History",
                    onClick: (event, rowData) => {
                      if (isIOSDevice()) {
                        getPDF(
                          config.domain + "service-history/" + rowData.vin
                        );
                        return;
                      }

                      getPDF_(
                        config.domain + "service-history/" + rowData.vin
                      );
                      // setRowData(rowData);
                      // setDialogOpen(true);
                    },
                  }
                  : null,

              (rowData) =>
                profile?.permission >= 5
                  ? {
                    icon: rowData.active === 1 ? Block : CheckBox,
                    tooltip:
                      rowData.active === 1
                        ? "Block Customer"
                        : "Unblock Customer",
                    onClick: (event, rowData) => {
                      Swal.fire({
                        text:
                          (rowData.active === 1 ? "Block" : "Un-block") +
                          " " +
                          rowData.first_name +
                          "?",
                        // input: "number",
                        // html: "<div style='text-align:left;'>Amount to Pay</div>",
                        // inputValue: totals.total,
                        // inputAttributes: {
                        //   max: totals.total,
                        // },
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          customerStatusChange(rowData)
                            .then((res) => {
                              setToastMessage(res.data.message);
                              setIsToastOpen(true);
                              loadData();
                              // resolve();
                            })
                            .catch((error) => {
                              setToastMessage(error.message);
                              setIsToastOpen(true);
                              // reject();
                            });
                        } else if (result.isDenied) {
                          // Swal.fire('Changes are not saved', '', 'info')
                        }
                      });

                      // if(isIOSDevice()) {
                      //    getPDF(config.domain + "service-history/" + rowData.vin);
                      //    return;
                      // }

                      // getPDF_(config.domain + "service-history/" + rowData.vin);
                      // setRowData(rowData);
                      // setDialogOpen(true);
                    },
                  }
                  : null,
            ]}
          />
        </Grid>
      </Grid>
      {/* </form> */}
      <Toast
        isOpen={isToastOpen}
        handleClose={handleToastClose}
        message={toastMessage}
      />
    </div>
  );
}
