import React, { useRef, useState, useEffect } from "react";
import {  useQuery } from 'react-query'
import Chip from '@mui/material/Chip';
import { Paper, Button, Box, Typography, Divider, List, ListItem, ListItemText, ListItemIcon, Badge, AppBar, Toolbar, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { dataProvider, getProfile, formatter, searchGlobalProduct, dataProvider2 } from "../util";
import MaterialTable from "material-table";
import TextField from '@mui/material/TextField';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
import { Autocomplete } from "@material-ui/lab";
import Swal from "sweetalert2";
import AdaptDialog from "../components/AdaptDialog";
import { tableIcons } from "../components/TableIcons";
import { number } from "prop-types";
import Inventory from "@mui/icons-material/Inventory";
import Grid from '@mui/material/Grid';
import { Skeleton } from "@mui/material";
import moment from "moment";
import { getInventoryLog } from "../api/inventoryApi";
import { AddToPhotos, Delete, Inbox } from "@material-ui/icons";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
var Barcode = require('react-barcode');
var _ = require('lodash');

var activeID = null;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
    paddingTop: 60,
  },
  formRoot: {
    "& > *": {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },

  fRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      // width: '200',
    },
  },

  form_Root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      // width: '50%',
    },
  },

  flow: {
    width: "100%",
  },
  icon: {
    color: "red",
  },
  section1: {
    marginTop: 20,
  },
  subTitle: {
    color: "#0635C9",
    textAlign: "left",
    fontSize: "1.2em",
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function Products() {
  const route = "/v2/inventory/product";
  const compName = "Product";
  const [photos, setPhotos] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogOpen2, setIsDialogOpen2] = useState(false);
  const [isDialogOpen_up, setIsDialogOpen_up] = useState(false);
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [selectValue, setSelectValue] = useState("");
  const [formData, setFormData] = useState({});
  var tableRef = useRef();
  const [fetchedData, setFetchedData] = React.useState({});
  const [product, setProduct] = React.useState({});
  const [productSearch, setProductSearch] = React.useState([]);
 
  const formFields = [
    {
      id: 'title',
      label: 'Title',
      required: true,
      type: 'text'
    }, {
      id: 'description',
      label: 'Description',
      required: true,
      type: 'text'
    },
    {
      id: 'stock',
      label: 'Stock',
      required: true,
      type: 'number',
      updater: true
    }, {
      id: 'low_stock',
      label: 'Low Stock',
      required: true,
      type: 'number',
      updater: true
    },
    {
      id: 'price',
      label: 'Price',
      required: true,
      type: 'number'
    },
    {
      id: 'cost',
      label: 'Cost',
      required: false,
      type: 'number'
    },
    {
      id: 'supplier',
      label: 'Supplier',
      required: false,
      type: 'select',
      data: suppliers
    },
    {
      id: 'barcode',
      label: 'Barcode',
      required: false,
      type: 'text'
    }
  ]

  const handleDialogClose = event => {
    setIsDialogOpen(false);
  }
  const handleDialogClose2 = event => {
    setPhotos([]);
    setIsDialogOpen2(false);
  }
  const handleDialogClose_up = event => {
    setIsDialogOpen_up(false);
  }
  const handleFormDataChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const inventoryLogQ = useQuery({
    queryKey: ['inventoryLog_'+formData?.id], queryFn: async () => {
      const d = await getInventoryLog(formData?.id)
      console.log(formData, "form-data****")
      return d.data
    }, refetchOnWindowFocus: false,
  });

  const handleAddNewRow = event => {
    event.preventDefault();
    if (_.isEmpty(formData)) return false;
    formData.supplier_name = _.find(suppliers, { id: formData.supplier })?.bizname;
    dataProvider('post', route, formData)
      .then((res) => {
        if (res.data.error === false) {
          Swal.fire({
            text: res.data.message,
            icon: 'success',
            toast: true,
            showConfirmButton: false,
            position: 'bottom-end',
            'timer': 2000,
          })
          loadData();
          reset();

        }
      })
      .catch((error) => {
      });



  }
  const handleEditRow = event => {
    event.preventDefault();
    if (_.isEmpty(formData)) return false;
    formData.supplier_name = _.find(suppliers, { id: formData.supplier })?.bizname;
    dataProvider('put', route, formData)
      .then((res) => {
        if (res.data.error === false) {
          Swal.fire({
            text: res.data.message,
            icon: 'success',
            toast: true,
            showConfirmButton: false,
            position: 'bottom-end',
            'timer': 2000,
          })
          loadData();
          // reset();

        }
      })
      .catch((error) => {
        // Swal.fire(
        //   undefined,
        //   error.message,
        //   'warning',
        // )
      });
  }
  const handleUpdateRow = event => {
    event.preventDefault();
    // console.log(formData)

    if (_.isEmpty(fetchedData)) return false;
    fetchedData.receival = true;
    // fetchedData.stock = formData.stock;
    setLoading(true)
    dataProvider('put', route, formData)
      .then((res) => {
        setTimeout(() => { setLoading(false) }, 1500)
        if (res.data.error === false) {
          Swal.fire({
            text: res.data.message,
            icon: 'success',
            toast: true,
            showConfirmButton: false,
            position: 'bottom-end',
            'timer': 2000,
          })
          loadData();
          populateProduct();
          // // reset();
          // setFormData({
          //   id:
          //   stock: null,
          //   receival: true
          // })
          setFetchedData({ ...fetchedData, stock: parseInt(fetchedData.stock) + parseInt(formData.stock) });
          setFormData({ ...formData, stock: null })
          Array.from(document.querySelectorAll("input")).forEach(
            (input) => (input.value = "")
          );


          return true;
        }
      })
      .catch((error) => {
        setTimeout(() => { setLoading(false) }, 1500)
        console.log(error, "errr")
      });
  }

  const [profile, setProfile] = React.useState({ username: "", permission: 0 });
  useEffect(() => {
    loadProfile();
    loadSuppliers();
    // loadData();
  }, []);

  async function loadProfile() {
    const profile = await getProfile();
    setProfile(profile);
  }

  async function loadSuppliers() {
    var i = 0;
    try {
      const mydata = await dataProvider("get", '/v2/inventory/suppliers', []);

      // mydata.data.data.unshift({id:'', bizname:''})
      // console.log(mydata.data.data, "shift")
      if (mydata) setSuppliers(mydata.data.data);

    } catch (error) {
      i++;
      if (i > 5) return false;
      console.error(error);
      // loadSuppliers();
      Swal.fire(
        undefined,
        error.message,
        'warning',
      )
    }
  }


  async function loadData() {
    tableRef.current.onQueryChange()
  }

  const reset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (input) => (input.value = "")
    );
    document.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0].click();
    setFormData({});
  }


  const handleDeleteRow = (rowData) => {
    return new Promise((resolve, reject) => {
      dataProvider("delete", route, rowData)
        .then((res) => {
          Swal.fire({
            text: res.data.message,
            icon: 'success',
            toast: true,
            position: 'bottom-end',
            'timer': 2000,
          })
          loadData();
          resolve();
        })
        .catch((error) => {
          Swal.fire({
            text: error.message,
            icon: 'warning',
            toast: true,
            position: 'bottom-end',
            'timer': 2000,
          })
          reject();
        });
    })
  }
  const view_admin = [
    { title: "ID", field: "id", editable: false },
    {
      title: "Title",
      field: "title"
    },
    {
      title: "Photo", field: "low_stock",
      render: (rowData) => {
        return rowData.photos[0] !== undefined?<span>  <SimpleReactLightbox>
        <SRLWrapper><a href={rowData.photos[0].thumbnail}><img style={{width:120}} src={rowData.photos[0].thumbnail} /></a></SRLWrapper></SimpleReactLightbox></span>:''
      }
    },
    { title: "Description", field: "description" },
    {
      title: "Stock", field: "stock",
      render: (rowData) => {
        if (rowData.stock <= 0) {
          return <span style={{ color: 'red', fontWeight: '600' }}>{rowData.stock}</span>
        } else if (rowData.stock < rowData.low_stock) {
          return <span style={{ color: '#e27602', fontWeight: '600' }}>{rowData.stock}</span>
        } else {
          return <span style={{ color: '#262626', fontWeight: '600' }}>{rowData.stock}</span>
        }

      }
    },
    {
      title: "Low Stock", field: "low_stock",
      hidden: profile?.permission >= 5 ?false:true,
      render: (rowData) => {
        return <span style={{ color: '#999', fontWeight: 'light' }}>{rowData.low_stock}</span>
      }
    },
    {
      title: "Price", field: "price",
      render: (rowData) => {
        return <Chip size="small" label={formatter.format(rowData.price)} color="success" variant="outlined" />
      }
    },
    { title: "Cost", 
    hidden: profile?.permission >= 5 ?false:true,
    field: "cost", render: (rowData) => formatter.format(rowData.cost) },
    {
      title: "Supplier", field: "supplier",
      hidden: profile?.permission >= 5 ?false:true,
      render: (rowData) => _.find(suppliers, { id: rowData.supplier })?.bizname
    },
    { title: "Barcode", field: "barcode" },

    { title: "Last Updated", 
    field: "updated_at", render: (rowData) => rowData?.updated_at !== null?moment(rowData.updated_at).format("YYYY-MM-DD HH:mm"):'' },

    { title: "Supplier name", field: "supplier_name", hidden: true },


  ];

  const updateProductState = (e) => {
    if (e.target.value.length % 3 === 0) {
      setProduct({ ...product, [e.target.name]: e.target.value });
    }


  };

  useEffect(() => {
    populateProduct();
  }, [product])

  async function loadPhotos(id) {
    if (!id) return false;
    try {
      const mydata = await dataProvider("get", "/productPhotos/" + id, []);
      setPhotos(mydata.data);
    } catch (error) {
      Swal.fire(
        undefined,
        error.message,
        'warning',
      )
    }
  }

  let myFile = React.useRef();

  const [fileProgress, setFileProgress] = React.useState("hidden");
  async function saveFile(f) {
    setFileProgress("visible");
    const fdata = new FormData();
    fdata.append("myfile", f);
    fdata.append("product_id", activeID);
    // console.log(fdata)

    try {
      const mydata = await dataProvider2(
        "post",
        "/upload/product-photo/",
        fdata
      );
      //  setUserInfo(mydata.data.data);
      // console.log(mydata.data, "============-info");
      if (mydata.status === 200) {
      
        // Swal.fire(
        //   undefined,
        //   mydata.data.message,
        //   'info',
        // )
        loadPhotos(activeID);
      }
      setFileProgress("hidden");
      // clearFile();
      // loadInvoices();
    } catch (error) {
      Swal.fire(
        undefined,
        error.message,
        'warning',
      )
      setFileProgress("hidden");
    }
  }
  const handleFormChange = (e) => {
    if (e.target.files) {
      if (e.target.files[0]?.size > 10000000) {
   
        Swal.fire(
          undefined,
          "File size should be less than 10MB",
          'warning',
        )
        
        myFile.current.value = null;
        // setFormData({ ...formData, invoiceFile: null });
        setFormData((prev) => ({ ...prev, myfile: null }));
        return;
      }
      saveFile(e.target.files[0]);
      return;
    } else {
      // setFormData({ ...formData, [e.target.name]: e.target.value });
      setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  
  async function doDeletePhoto(id) {
    let del_index = photos.findIndex((i) => i.id === id);

    try {
      const mydata = await dataProvider("delete", "/productPhoto", { id: id });

      if (mydata.status === 200) {
        photos.splice(del_index, 1);
        // Swal.fire(
        //   undefined,
        //   mydata.data.message,
        //   'info',
        // )
        loadPhotos(activeID);
        
      }
    } catch (error) {
      Swal.fire(
        undefined,
        error.message,
        'warning',
      )
      
    }
  }

  async function deletePhoto(id) {
    // alert(id);
    // global activeID;
    if (!id) return false;

    Swal.fire({
      title: "Are you sure?",
      text: "Delete this photo",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, delete it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        doDeletePhoto(id);
      }
    });
  }

  const populateProduct = async () => {

    let id = null;
    if (product && product["search"]?.length > 1) {
      id = product["search"];
    } else {
      return false;
    }

    try {
      const data = await searchGlobalProduct(id);
      let mydata = data.data;

      if (typeof mydata === "undefined") {
        setProductSearch([]);
        return false;
      }
      // console.log(data.data[0])
      if (mydata[0] && mydata[0]["title"]) {
        setProductSearch(mydata);
      } else {
        setProductSearch([]);
      }
    } catch (error) {
      console.error(error);
    }

    //
  };
  let tableData = async query => {
    // let url = `http://localhost:1980/v2/inventory/products_` +
    //     `?per_page=${query.pageSize}` +
    //     `&page=${query.page + 1}` +
    //     `&search=${query.search}`;
    // const response = await fetch(url);
    // const result = await response.json();
    // console.log(result.data.total)
    // return {
    //     data: result.data.data,
    //     page: result.data.page - 1,
    //     totalCount: result.data.total,
    // };

    try {
      console.log(query, "QUERY")
      const result = await dataProvider("get", `/v2/inventory/products_` +
        `?per_page=${query.pageSize}` +
        `&page=${query.page + 1}` +
        `&orderBy=${query.orderBy?.field}` +
        `&orderDirection=${query.orderDirection}` +
        `&search=${query.search}`, []);

      if (!result) return { data: null, page: null, totalCount: null }
      return {
        data: result.data.data.data,
        page: result.data.data.page - 1,
        totalCount: result.data.data.total,
      };
    } catch (error) {
      console.error(error);
      Swal.fire(
        undefined,
        error.message,
        'warning',
      )
      return [];
    }

  }

  return (
    <div className={classes.root}>
      {/* <form className={classes.formRoot} onSubmit={handleSubmit}> */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaterialTable
            tableRef={tableRef}
            //           onSearchChange={(e) =>{
            // console.log(e)
            //           }}
            style={{ fontSize: 14 }}
            title={compName + "s"}
            columns={view_admin}
            icons={tableIcons}
            data={tableData ? tableData : null}
            // data={data ? data : null}
            // editable={
            //   profile?.permission >= 5
            //     ? {
            //       onRowUpdate: (newData, oldData) =>
            //         new Promise((resolve, reject) => {
            //           dataProvider("put", route, newData)
            //             .then((res) => {
            //               Swal.fire({
            //                 text: res.data.message,
            //                 icon: 'success',
            //                 toast: true,
            //                 position: 'bottom-end',
            //                 'timer': 2000,
            //               })
            //               loadData();
            //               resolve();
            //             })
            //             .catch((error) => {
            //               Swal.fire({
            //                 text: error.message,
            //                 icon: 'warning',
            //                 toast: true,
            //                 position: 'bottom-end',
            //                 'timer': 2000,
            //               })
            //               reject();
            //             });
            //         }),
            //     }
            //     : null
            // }
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 20, 50, 100, 150, 300],
              sorting: true,
              search: true,
              exportButton: true,
              rowStyle: (rowData) => {
                // console.log(rowData)

                if (rowData.stock <= rowData.low_stock && !(rowData.stock <= 0)) {
                  return { backgroundColor: "#fcecc2" };
                }
                // if (rowData.tableData.id % 2 === 0 && rowData.stock !== 0) {
                //   return { backgroundColor: "#eaf9f7" };
                // }

                if (rowData.stock <= 0) {
                  return { backgroundColor: "#fceffc" };
                }
              },
            }}
            actions={[
              {
                tooltip: "Refresh",
                icon: tableIcons.Refresh,
                position: "toolbar",
                onClick: () => {
                  loadData();
                },
              }, profile?.permission >= 5 ? {
                tooltip: "Add " + compName,
                icon: tableIcons.Add,
                position: "toolbar",
                onClick: async () => {
                  setIsDialogOpen(true);
                },
              } : null,
              profile?.permission >= 5 ?

                {
                  tooltip: "Receive " + compName,
                  icon: tableIcons.Inventory,
                  position: "toolbar",
                  onClick: async () => {
                    setProductSearch([]);
                    setFetchedData({});

                    setIsDialogOpen_up(true);
                  },
                } : null,

              profile?.permission >= 5 ?
                (rowData) => ({
                  icon: tableIcons.Edit,
                  tooltip: "Edit",
                  onClick: async (event, rowData) => {
                    setFormData(rowData)
                    activeID = rowData.id;
                    loadPhotos(rowData.id)
                    setIsDialogOpen2(true);
                  }
                }) : null,

              profile?.permission >= 5 ?
                (rowData) => ({
                  icon: tableIcons.Delete,
                  tooltip: "Delete",
                  onClick: async (event, rowData) => {
                    await Swal.fire({
                      text: 'Click delete to confirm.',
                      // showDenyButton: true,s
                      showCancelButton: true,
                      confirmButtonText: 'Delete',
                      // denyButtonText: `Don't save`,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        handleDeleteRow(rowData)
                        return true;
                      } else if (result.isDenied) {
                        return false;
                      }
                    })
                  }

                }) : null


            ]}
          />
        </Grid>
      </Grid>
      {/* </form> */}

      <AdaptDialog title={"Add " + compName} isOpen={isDialogOpen} onClose={handleDialogClose}>
        <Paper style={{ padding: '2em' }} >
          <form autoComplete="off" onSubmit={handleAddNewRow}>
            {/* <Box
            className={classes.form_Root}
            justifyContent="center" m={3} p={3}
          > */}

            <Grid container spacing={2}>
              {
                formFields.map(i =>
                  <Grid item xs={12} md={6} key={i.id}>

                    {i.type === 'select' ? <>
                      <Autocomplete
                        // value={formData.supplier}
                        clearOnBlur={false}
                        label={i.label} name={i.id}
                        options={i.data}
                        getOptionLabel={(option) => option.bizname}
                        // onChange={handleFormDataChange}
                        onChange={(event, newValue) => {
                          newValue && i.id && newValue.id && setFormData({ ...formData, [i.id]: newValue.id });
                        }}
                        style={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label={i.label} name={i.name} variant="outlined" />}
                      />
                    </> :
                      <TextField onKeyPress={e => e.key === 'Enter' && e.preventDefault()} type={i.type} required={i.required} fullWidth variant="outlined" onChange={handleFormDataChange} label={i.label} name={i.id} />}
                  </Grid>)
              }

              <Grid item xs={12} >
                <div style={{ marginTop: '3em', textAlign: 'right', }}>
                  <Button type={"button"} style={{ margin: 5 }} color="primary" variant="contained" type="submit">Save</Button>
                  <Button style={{ margin: 5 }} margin="normal" onClick={handleDialogClose}>Close</Button>
                </div>
              </Grid>
            </Grid>
            {/* </Box> */}
          </form>

        </Paper>
      </AdaptDialog>


      <AdaptDialog title={"Edit " + compName} isOpen={isDialogOpen2} onClose={handleDialogClose2}>

            <Toolbar>
              <Button
                autoFocus
                color="inherit"
                onClick={() => {
                  myFile.current.click();
                }}
              >
                <AddToPhotos style={{ width: 15, paddingRight: 3 }} /> Add
                Photo
              </Button>
            </Toolbar>
       

        <Paper style={{ padding: '2em' }} >
        <input
                      onChange={handleFormChange}
                      type="file"
                      name="photo"
                      ref={myFile}
                      style={{ visibility: "hidden" }}
                    />
          <form autoComplete="off" onSubmit={handleEditRow}>
            {/* <Box
            className={classes.form_Root}
            justifyContent="center" m={3} p={3}
          > */}

            <Grid container spacing={2}>

            
            {photos?.length > 0 ? (
<Grid item xs={12} md={12} key='photo_product'>
            <LinearProgress
              style={{ visibility: fileProgress, marginBottom: 15 }}
            />
              <SimpleReactLightbox>
                <SRLWrapper>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} style={{ maxWidth: "99vw" }}>
                      {photos.map((p) => (
                        <Grid item xs={12} md={3}>
                          <a href={p.src}>
                            <img style={{ width: "100%" }} src={p.src} />
                          </a>
                          <Button
                            color="inherit"
                            onClick={() => {
                              deletePhoto(p.id);
                            }}
                            style={{ textTransform: "capitalize" }}
                          >
                            <Delete style={{ width: 18, color: "#777" }} />
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </SRLWrapper>
              </SimpleReactLightbox></Grid>
            ):''}
              
              {

                formFields.map(i => {
                  return <Grid item xs={12} md={6} key={i.id}>
                    {i.type === 'select' ? <>
                      <Autocomplete
                        label={i.label} name={i.id}
                        options={i.data}
                        // value={formData.[i.id]}
                        defaultValue={_.find(i.data, { id: formData.[i.id] })}
                        getOptionLabel={(option) => option.bizname}
                        // onChange={handleFormDataChange}
                        onChange={(event, newValue) => {
                          // console.log(newValue);
                          setFormData({ ...formData, [i.id]: newValue.id });
                        }}
                        style={{ width: 300 }}
                        renderInput={(params) => <> <TextField {...params} label={i.label} name={i.name} variant="outlined" />
                          {/* <small>{(suppliers2?.filter(s => s.id === formData.[i.id]))[0]?.bizname}</small> */}
                        </>}
                      />
                    </> :

                      <TextField onKeyPress={e => e.key === 'Enter' && e.preventDefault()} type={i.type} value={formData.[i.id]} required={i.required} fullWidth variant="outlined" onChange={handleFormDataChange} label={i.label} name={i.id} />}
                  </Grid>
                }
                )
              }
              <Grid item xs={12} >
                <div style={{ marginTop: '3em', textAlign: 'right', }}>
                  <Button type="submit" style={{ margin: 5 }} color="primary" variant="contained">Save</Button>
                  <Button style={{ margin: 5 }} margin="normal" onClick={handleDialogClose2}>Close</Button>
                </div>
              </Grid>
            </Grid>
            {/* </Box> */}
          </form>

        </Paper>
      </AdaptDialog>

      <AdaptDialog title={"Receive Inventory"} isOpen={isDialogOpen_up} onClose={handleDialogClose_up}>
        <Paper style={{ padding: '2em', minHeight: '50vh', height: 'auto' }} >
          <form autoComplete="off" onSubmit={handleUpdateRow}>
            {/* <Box
            className={classes.form_Root}
            justifyContent="center" m={3} p={3}
          > */}

            <Grid container spacing={2} container justifyContent={"center"}>

              <Grid item xs={12} md={12}>
                <Autocomplete

                  id="product_search"
                  //   onChange={productUpdate}
                  onChange={(event, newValue) => {

                    setFetchedData(newValue)
                    setFormData({
                      id: newValue?.id,
                      stock: null,
                      receival: true
                    })


                  }}
                  options={productSearch}
                  getOptionLabel={(option) => {
                    if (option.barcode) {
                      return option.title + ' - ' + option.description + ' / ' + option.barcode
                    } else {
                      return option.title + ' ' + option.description
                    }

                  }}
                  renderOption={(option) => {
                    return <React.Fragment>

                      <div style={{ color: "#666", margin: 3 }}>
                        <div>{option.title + " "}<Chip size="small" label={formatter.format(option["price"])} color="success" variant="outlined" /></div>
                        <small style={{ fontSize: '0.9em', color: '#666' }}>{option["description"]}</small>
                        <div style={{ fontSize: '0.8em', color: '#aaa' }}>{option["barcode"]}</div>

                      </div> <Divider />
                    </React.Fragment>
                  }}
                  //   style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      autoFocus
                      {...params}
                      label="Find product"
                      variant="outlined"
                      placeholder="Search by barcode, title, id, description"
                      value={product.search}
                      name="search"
                      onChange={updateProductState}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <div className={classes.section1}>
                  <Grid container justifyContent={"center"} spacing={2}>
                    <Grid item xs='auto' >

                      <Typography gutterBottom variant="h5">
                        {fetchedData?.title ? <Inventory style={{ color: '#333' }} /> : ''}{" "}{fetchedData?.title}
                      </Typography>
                      <Typography color="textSecondary" variant="body1 ">
                        {fetchedData?.description}
                      </Typography>
                    </Grid>

                  </Grid>

                  {/* <Divider style={{ marginTop: 15 }} /> */}
                </div>
              </Grid>
              {fetchedData?.id ? <Grid item xs={12} md={6}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Recent inventory
          </Typography>
          <div style={{ maxHeight: '130px', overflowY: 'auto' }}>
            <List dense>
              {
              inventoryLogQ?.data?.map((item, index) => 
              <ListItem>
              <ListItemIcon>
              <Badge color="secondary" badgeContent={item.amount}>
              <Inbox />
            </Badge>
          </ListItemIcon>
               <ListItemText
                 primary={moment(item.created_at).format("MMMM Do YYYY, HH:mm")}
                 secondary={"Inventory received"}
               />             
             </ListItem>
              )}
            </List>
            </div>
        </Grid>:''}

              {fetchedData?.id ?
                <React.Fragment >
                  <Grid item xs={12} md={6}>
                    <Typography gutterBottom variant="h6">
                      {fetchedData?.cost ? formatter.format(fetchedData?.cost) : '$0'}
                    </Typography>
                    <Typography color="textSecondary" variant="body1 ">
                      Cost from supplier
                    </Typography>
                  </Grid>
                     <Grid item xs={12} md={6}>
                    <Typography gutterBottom variant="h6">
                      {fetchedData?.price ? formatter.format(fetchedData?.price) : '$0'}
                    </Typography>
                    <Typography color="textSecondary" variant="body1 ">
                      Selling price
                    </Typography>
                  </Grid>
                 
                  <Grid item xs={12} >
                    <Divider />
                  
                  </Grid>
                  <Grid item xs={12} md={6} >
                    <Typography gutterBottom variant="h6">
                      {loading === true ? <Skeleton width={90} animation="wave" /> : fetchedData?.stock}
                    </Typography>
                    <Typography color="textSecondary" variant="body1 ">
                      Current stock
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField coloir="success" focused required onChange={handleFormDataChange} variant="outlined" name="stock" value={formData.stock} />

                    <div><Typography color="textSecondary" variant="body2 ">Enter amount of items being received</Typography></div>

                  </Grid>
                  {fetchedData?.barcode ? <Grid item xs={12}>
                    <Grid container justifyContent={"center"} spacing={0} style={{ marginTop: 30 }}>
                      <Barcode height={40} lineColor="#333" value={fetchedData?.barcode} />
                    </Grid>
                  </Grid> : ''}
                </React.Fragment> : ''}
              <Grid item xs={12} >
                <div style={{ marginTop: '3em', textAlign: 'right', }}>
                  {fetchedData?.id ? <Button type="submit" style={{ margin: 5 }} color="primary" variant="contained">Save</Button> : ''}
                  <Button style={{ margin: 5 }} margin="normal" onClick={handleDialogClose_up}>Close</Button>
                </div>
              </Grid>
            </Grid>
            {/* </Box> */}
          </form>

        </Paper>
      </AdaptDialog>


    </div >
  );
}
