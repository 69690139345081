import React, { useState } from "react";
// import { useHistory } from "react-router-dom";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Paper,
  Box,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { dataProvider } from "../util";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Swal from "sweetalert2";

var moment = require("moment");
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
    paddingTop: 60,
    // padding: 30,
    // background: "#f3f3f3"
  },
  formRoot: {
    "& > *": {
      //   margin: theme.spacing(1),
      //   minWidth: '100%',
      //   background: 'red'
    },
  },
  flow: {
    width: "100%",
  },
  icon: {
    color: "red",
  },
  section1: {
    marginTop: 20,
  },
  subTitle: {
    color: "#0635C9",
    textAlign: "left",
    fontSize: "1.2em",
  },
}));

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function Report() {
  // let history = useHistory();
  const classes = useStyles();
  const [titleData, setTitleData] = useState("");
  const [salesTitleData, setSalesTitleData] = useState("");
  const [fdata, setData] = useState({});
  const [reportData, setReportData] = useState({});
  const [productData, setProductData] = useState([]);
  // const [totals, setTotals] = useState({labour: 0, products:0})

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
  ];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Sales Report" + titleData,
      },
    },
  }; 
  
  const sales_options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Daily Sales Report" + salesTitleData,
      },
    },
  };

  const chart_data = {
    labels: reportData?.invoice?.map((i) => i.dt.substring(0, 10)),
    datasets: [
      {
        label: "Labour",
        data: reportData?.invoice?.map((i) => i.labour),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Products",
        data: reportData?.invoice?.map((i) => i.products),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Discounts",
        data: reportData?.invoice?.map((i) => i.discounts),
        backgroundColor: "rgb(129, 93, 163)",
      },
    ],
  };
  
  const sales_chart_data = {
    labels: reportData?.sales?.map((i) => i.dt.substring(0, 10)),
    datasets: [
      {
        label: "Sales",
        data: reportData?.sales?.map((i) => i.sales),
        backgroundColor: "#50EC1F",
      }
    ],
  };

  const formChange = (e) => {
    setData({
      ...fdata,
      [e.target.name]: moment(e.target.value).format("YYYY-MM-DD"),
    });
  };

  const report = async (e) => {
    if (!fdata.start_date) {
      return Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Select start date",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    if (!fdata.end_date) {
      return Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Select end date",
        showConfirmButton: false,
        timer: 1500,
      });
    }

    if (moment(fdata.end_date).diff(moment(fdata.start_date), "days") > 365) {
      return Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Do reports within 365 days",
        showConfirmButton: false,
        timer: 1500,
      });
    }

    try {
      const mydata = await dataProvider("post", "/report", fdata);
      const products_data = await dataProvider(
        "post",
        "/report-products",
        fdata
      );
      const pdata = products_data.data;

      // console.log(pdata)

      let arr = [];
      let sum = 0;
      pdata.forEach((p) => {
        let prod = JSON.parse(p.products); //all invoices
        if (prod.length > 0) {
          //iterate each invoice product

          prod.forEach((invoice_product) => {
            sum = parseFloat(invoice_product.price) * parseInt(invoice_product.qty) + sum;
            console.log(invoice_product.qty, invoice_product.title);
            console.log(invoice_product, "product");
            let index = arr.findIndex((x) => x.title === invoice_product.title && x.description === invoice_product.description  );
            // console.log(index)
            if (index > -1) {
              let ptotal = (parseFloat(invoice_product.price) * parseInt(invoice_product.qty)) + arr[index].total
              
              arr[index] = {
                title: invoice_product.title,
                description: invoice_product.description,
                qty: parseInt(invoice_product.qty) + arr[index].qty,
                total: ptotal,
              };
            } else {
              //insert

              let ptotal = parseFloat(invoice_product.price) * parseInt(invoice_product.qty);
              arr.push({
                title: invoice_product.title,
                description: invoice_product.description,
                qty: parseInt(invoice_product.qty),
                total: ptotal,
              });
            }
          });
          //find index

          // console.log(prod.title, "prod")
          // let index = arr.findIndex((x) => x.title === prod.title);
          // // if found then update array
          // console.log(index, "index");
          // if (index) {
          //   arr[index] = {
          //     title: prod.title,
          //     qty: prod.qty + arr[index].qty,
          //     total: prod.total + arr[index].total,
          //   };
          // } else { //insert
          //   arr.push({
          //     title: prod.title,
          //     qty: prod.qty,
          //     total: prod.total,
          //   });
          // }
        }
      });

      arr.sort((a, b) => (a.qty < b.qty) ? 1 : -1)
      setProductData(arr);
      // console.log(arr, "Array"+sum);
      var p = 0;
      var l = 0;
      var d = 0;
      mydata.data.invoice.map((i) => {
        p += i.products;
        l += i.labour;
        d += i.discounts;
      });
      setTitleData(
        ` - Labour: ${l?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })} Products: ${p?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}  Discounts: ${d?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}`
      );

      var tot_sales = 0;
      mydata.data.sales.map((i) => {
        tot_sales += i.sales;
      });
      setSalesTitleData(
        ` - Total Sales: ${tot_sales?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}`
      );

      setReportData(mydata.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.root}>
      {/* <form className={classes.formRoot} onSubmit={handleSubmit}> */}
      <Grid container spacing={2} style={{ flexGrow: 1 }}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h2" style={{ fontSize: "2em" }}>
            Reports
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="start_date"
            name="start_date"
            label="Start"
            type="date"
            onChange={formChange}
            // defaultValue="2017-05-24"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>{" "}
        <Grid item xs={6}>
          <TextField
            id="end_date"
            name="end_date"
            label="End"
            type="date"
            onChange={formChange}
            // defaultValue="2017-05-24"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            name="sales"
            variant="contained"
            value="sales"
            color="primary"
            onClick={() => report("sales")}
          >
            Sales Report
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Bar options={options} data={chart_data} />
        </Grid>
        <Grid item xs={12}>
          <Bar options={sales_options} data={sales_chart_data} />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "2em" }}>
          <Typography gutterBottom variant="h5" style={{}}>
            Inventory Depletion{" "}
            {fdata.start_date
              ? ` for ${fdata.start_date} to ${fdata.end_date}`
              : ""}
          </Typography>

          <Box display="flex" justifyContent="center" alignItems="center">
            <Grid xs={12} md={10}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Title</b>
                      </TableCell>
                      <TableCell>
                        <b>Description</b>
                      </TableCell>
                      <TableCell align="right">
                        <b>Qty</b>
                      </TableCell>
                      <TableCell align="right">
                        <b>Total</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productData.map((row) => (
                      <TableRow key={row.title+row.description}>
                        <TableCell component="th" scope="row">
                          {row.title}
                        </TableCell>
                        <TableCell align="right">{row.description}</TableCell>
                        <TableCell align="right">{row.qty}</TableCell>
                        <TableCell align="right">{row.total.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {/* </form> */}
    </div>
  );
}
