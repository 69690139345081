import React from "react";
import { Grid, Typography, Divider } from "@material-ui/core";
import footer_logo from "../bla-strokes.png";
import { getBuild } from "../util";
import InstallPWA from "./InstallPWA";

export default function Footer() {
  const [build, setBuild] = React.useState("");

  React.useEffect(() => {
    getBuild()
      .then((res) => {
        // console.log(res);
        setBuild("Version: 3.0 / build:" + res.build);
      })
      .catch((e) => {
        console.log(e);
      });
  },[build]);
  return (
    <Grid container style={{ marginTop: 50, padding: 50 }}>
      <Grid item xs={12}>
        <div>
          <InstallPWA />
        </div>
        <Divider style={{ marginBottom: 20 }} />
        <img alt="footer" src={footer_logo} width={60} />
        {/* <Typography variant="subtitle1" style={{ color: "#222" }}>
          Scott's Auto Electrical Supplies
        </Typography> */}
        {/* <div>
        <Typography variant="caption" style={{color:"#b6b6b6"}}>
            9 Churchill Avenue, Montego Bay / (876) 971-1859 (876) 859-1704
        </Typography>
        </div> */}
        <div>
          <Typography
            variant="caption"
            style={{ color: "#b6b6b6", fontSize: "0.6em" }}
          >
            powered by{" "}
            <span
              style={{
                color: "#fff",
                background: "#333",
                padding: "1px 2px 1px 2px",
              }}
            >
              <b>MALIDADDY</b> Ltd.
            </span>
          </Typography>
        </div>
        <div>
          <Typography
            variant="caption"
            style={{ color: "#b6b6b6", fontSize: 9 }}
          >
            {build ? build : ""}
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}
