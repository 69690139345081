import React, { forwardRef, useState, useEffect } from "react";

import {
  Grid,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  Avatar,
  ListItemAvatar,
  ListItemText,
  FormControlLabel,
  Checkbox,
  Button,
  TextField,
  Divider,
  IconButton,
  AppBar,
  Dialog,
  Toolbar,
  Slide,
  Box,
  LinearProgress,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import Toast from "../components/Toast";
import AlertDialog from "../components/AlertDialog";
import Modal from "@material-ui/core/Modal";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import CloseIcon from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import SelectAll from "@material-ui/icons/SelectAll";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";
// import Gallery from "react-grid-gallery";
import { copyElementText, dataProvider, dataProvider2 } from "../util";
import {
  getServiceList,
  putServiceItem,
  doAPI,
  getProfile,
  putService,
} from "../util";
import {
  Refresh,
  Note,
  Comment,
  EditRounded,
  Phone,
  Email,
  Description,
  PhotoLibrary,
  Delete,
  AttachMoney,
  Build,
  RateReview,
  CommentOutlined,
  ExpandMore,

} from "@material-ui/icons";
import moment from "moment";
import footer_logo from "../bla-strokes.png";
import { useHistory } from "react-router-dom";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import "./sweet.css";
import _ from "lodash";
import { BuildCircle, BuildCircleOutlined, BuildCircleRounded, Engineering, NoteAlt } from "@mui/icons-material";
import { AlertTitle } from "@mui/material";
import MyMenu from "../features/MyMenu";
let lodash = require("lodash");


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
    paddingTop: 60,
    // padding: 30,
    // background: "#f3f3f3"
  },
  formRoot: {
    "& > *": {
      //   margin: theme.spacing(1),
      //   minWidth: '100%',
      //   background: 'red'
    },
  },
  flow: {
    width: "100%",
  },
  icon: {
    color: "red",
  },
  section1: {
    marginTop: 20,
  },
  subTitle: {
    color: "#0635C9",
    textAlign: "left",
    fontSize: "1.2em",
  },
  paper: {
    position: "absolute",
    width: "auto",
    maxHeight: "100vh",
    top: 20,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #666",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "scroll",
    // paddingBottom:20,
    // marginBottom:20
  },
  jobBtn: {
    // padding:2,
    margin: "2px 6px 2px 0px",
  },
}));

function getModalStyle() {
  const top = 0;
  const left = 0;

  return {
    overflow: "scroll",
    top: `${top}%`,
    left: `${left}%`,
    bottom: 30,
    // transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function BookingsTable(props) {
  var activeID = null;
  var activeID_c = null;

  const op = ["booked", "received", "active", "complete", "canceled"];
  let history = useHistory();
  const classes = useStyles();
  const [serviceList, setServiceList] = React.useState([]);
  const [tableTitle, setTableTitle] = React.useState("");
  const [modalScreen, setModalScreen] = React.useState("services");
  const [actionID, setActionID] = React.useState(null);
  const [actionID_c, setActionID_c] = React.useState(null);
  const [photos, setPhotos] = React.useState([]);
  const [subject, setSubject] = React.useState({});
  const [service, setService] = React.useState({});
  const [openModal, setOpenModal] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [fileProgress, setFileProgress] = React.useState("hidden");
  const [modalStyle] = React.useState(getModalStyle);

  const [techie, setTechie] = React.useState([]);
  const [openPhotos, setOpenPhotos] = React.useState(false);
  const [openSL, setOpenSL] = React.useState(false);
  const [pgSize, setPGSize] = React.useState(10);
  const tableRef = React.useRef(null)

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const handleClosePhotos = () => {
    setOpenPhotos(false);
    setPhotos([]);
  }; const handleCloseSL = () => {
    setOpenSL(false);
    setServiceLog([]);
  };

  const handleChange = (event) => {
    setService({ ...service, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    setTableTitle(props.option.title);
    if (props.data && (props.data.length < 10 && props.data.length > 0)) {
      setPGSize(props.data.length)
    }
  }, [props]);

  useEffect(() => {
    console.log(pgSize)

    if (pgSize < 10) tableRef.current.dataManager.changePageSize(pgSize)


  }, [pgSize]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [mcomment, setComment] = useState("");
  const [snote, setServiceNote] = useState("");
  const [invoiceJob, setInvoiceJob] = useState([]);
  const handleComment = (e) => {
    setComment(e.target.value);
  };
  const handleServiceNote = (e) => {
    setServiceNote(e.target.value);
  };

  const [profile, setProfile] = React.useState({ username: "", permission: 0 });
  useEffect(() => {
    async function fetchServices() {
      if (sessionStorage.getItem("services")) {
        let session_data = JSON.parse(sessionStorage.getItem("services"));
        setServiceList(session_data.data);
        // scrolly("pageTop");
      } else {
        try {
          let live_data = await getServiceList();
          if (typeof live_data === "undefined" || live_data === "Network Error")
            throw new Error("Please restart application. Network Error.");
          sessionStorage.setItem("services", JSON.stringify(live_data));
          setServiceList(live_data.data);
        } catch (error) {
          setToastMessage(error.message);
          setIsToastOpen(true);
        }
      }
    }
    //   loadBookings();
    fetchServices();

    loadProfile();

    loadTechnicians();
  }, []);

  let myFile = React.useRef();

  async function saveFile(f) {
    setFileProgress("visible");
    const fdata = new FormData();
    fdata.append("myfile", f);
    fdata.append("job_id", activeID);
    // console.log(fdata)

    try {
      const mydata = await dataProvider2(
        "post",
        "/upload/service-photo/",
        fdata
      );
      //  setUserInfo(mydata.data.data);
      // console.log(mydata.data, "============-info");
      if (mydata.status === 200) {
        setToastMessage(mydata.data.message);
        setIsToastOpen(true);
        loadPhotos(activeID);
      }
      setFileProgress("hidden");
      // clearFile();
      // loadInvoices();
    } catch (error) {
      console.log(error);
      // loadInvoices();
      setToastMessage(error.message);
      setIsToastOpen(true);
      setFileProgress("hidden");
    }
  }

  async function loadPhotos(id) {
    if (!id) return false;
    try {
      const mydata = await dataProvider("get", "/servicePhotos/" + id, []);
      setPhotos(mydata.data);
    } catch (error) {
      setToastMessage(error.message);
      setIsToastOpen(true);
    }
  }

  async function loadTechnicians() {
    try {
      const mydata = await dataProvider("get", "/v2/auth/user-list/3", []);
      console.log(mydata.data)
      setTechie(mydata.data.data)
      // setData(mydata.data.data);
      // setPhotos(mydata.data);
    } catch (error) {
      setToastMessage(error.message);
      setIsToastOpen(true);
    }
  }


  async function doDeletePhoto(id) {
    let del_index = photos.findIndex((i) => i.id === id);

    try {
      const mydata = await dataProvider("delete", "/servicePhoto", { id: id });

      if (mydata.status === 200) {
        photos.splice(del_index, 1);
        setToastMessage(mydata.data.message);
        setIsToastOpen(true);
      }
    } catch (error) {
      setToastMessage(error.message);
      setIsToastOpen(true);
    }
  }

  async function deletePhoto(id) {
    // alert(id);
    // global activeID;
    if (!id) return false;

    Swal.fire({
      title: "Are you sure?",
      text: "Delete this photo",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, delete it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        doDeletePhoto(id);
      }
    });
  }

  /* Get Invoices Via Job */
  async function getInvoiceViaJob(id) {
    if (!id) return false;
    try {
      const mydata = await dataProvider("get", "/invoice-job/" + id.replace("#", ""), null);

      if (mydata.status === 200) {
        console.log(mydata.data);
        setInvoiceJob(mydata.data)
        //   photos.splice(del_index,1);
        //   setToastMessage(mydata.data.message);
        // setIsToastOpen(true);
      }
    } catch (error) {
      setToastMessage(error.message);
      setIsToastOpen(true);
    }
  }

  const clearFile = () => {
    myFile.current.value = null;
    // fileName.current.value = null;
    setFormData({ ...formData, invoiceFile: null });
    // setFormData({ ...formData, invoiceName: "" });
  };

  const handleFormChange = (e) => {
    // console.log(e.target.files[0]," file*********")
    // console.log(actionID, "AID")
    if (e.target.files) {
      if (e.target.files[0]?.size > 10000000) {
        setToastMessage("File size should be less than 10MB");
        setIsToastOpen(true);
        myFile.current.value = null;
        // setFormData({ ...formData, invoiceFile: null });
        setFormData((prev) => ({ ...prev, myfile: null }));
        return;
      }
      // setFormData((prev)=> ({ ...prev, [e.target.name]: e.target.files[0] }) );
      // setFormData((prev)=> ({ ...prev, [e.target.name]: e.target.files[0] }) );

      // fdata.append("myfile1", e.target.files);
      // console.log(e.target.files[0]," file*********")
      saveFile(e.target.files[0]);
      return;
    } else {
      // setFormData({ ...formData, [e.target.name]: e.target.value });
      setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  async function loadProfile() {
    const profile = await getProfile();
    setProfile(profile);
  }

  //Toast ----------------------------------------
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const handleToastClose = () => {
    setIsToastOpen(false);
  };

  const updateServices = () => {
    // console.log(subject.id);

    let save_service = [];
    Object.entries(service).forEach((v, i, a) => {
      // console.log(v);
      if (v[1] === true) {
        save_service.push(v[0]);
      }
    });
    // console.log(save_service);
    putServiceItem({ customer: subject, service: save_service })
      .then((res) => {
        // console.log(res)
        // setIsOpen(false);
        setToastMessage(res.data.message);
        setIsToastOpen(true);
        setTimeout(() => {
          handleCloseModal();
          //   loadBookings();
          // props.refresher();
          loadData();
        }, 1000);
      })
      .catch((error) => {
        setToastMessage(error.message);
        setIsToastOpen(true);
      });
  };

  /* Update Comment */
  const updateComment = () => {
    doAPI("comment", { id: subject.id.replace("#", ""), value: mcomment })
      .then((res) => {
        //   console.log(res,"upp")
        setToastMessage(res.data.message);
        setIsToastOpen(true);
        setComment("");
        setTimeout(() => {
          handleCloseModal();
          // props.refresher();
          loadData();
        }, 1000);
      })
      .catch((error) => {
        setToastMessage(error.data.message);
        setIsToastOpen(true);
      });
  };
  /* Service Note */
  const updateServiceNote = () => {
    doAPI("service-note", { id: subject.id.replace("#", ""), value: snote })
      .then((res) => {
        //   console.log(res,"upp")
        setToastMessage(res.data.message);
        setIsToastOpen(true);
        setServiceNote("");
        setTimeout(() => {
          handleCloseModal();
          // props.refresher();
          loadData();
        }, 1000);
      })
      .catch((error) => {
        setToastMessage(error.data.message);
        setIsToastOpen(true);
      });
  };

  const executeServiceEdit = (data) => {
    setModalScreen("services");
    handleOpenModal();
    setSubject({ name: data.first_name, id: data.id });

    //generate true values for service check
    let check_services = {};
    const obj_service = JSON.parse(data.service);
    Object.entries(obj_service).forEach((v, i, a) => {
      check_services = { ...check_services, [v[1]]: true };
    });
    setService(check_services);
  };

  const executeCommentEdit = (data) => {
    setModalScreen("comment");
    handleOpenModal();
    setSubject({ name: data.first_name, id: data.id });
    setComment(data.comment);
  };
  const executeServiceNoteEdit = (data) => {
    setModalScreen("service_note");
    handleOpenModal();
    setSubject({ name: data.first_name, id: data.id });
    setServiceNote(data.service_note);
  };


  // const updateServiceLog = async (id, value) => {
  //   try {

  //     const mydata = await dataProvider("put", "/service-log", {
  //       id: id,
  //       value: value
  //     });
  //     if (mydata.status !== 200) {       
  //       return false;
  //     }
  //     return true;

  //   } catch (error) {
  //     Swal.fire('Error!', error?.message, 'error')
  //   }
  // };

  const [serviceLog, setServiceLog] = React.useState([]);

  const removeServiceLog = async (id, job_id, msg) => {
    Swal.fire({
      text: 'Delete "' + msg.substring(0, 15) + '..."?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Delete',
      denyButtonText: `Don't save`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // let arr = serviceLog;
        // _.pullAt(arr, [i]);
        // setServiceLog((prev) => [...prev, ...arr])
        // //request UPDATE

        try {
          const mydata = await dataProvider2(
            "DELETE",
            "/service-log/",
            { id: id }
          );

          if (mydata.status === 200) {
            Swal.fire({
              icon: 'success',
              text: mydata.data.message,
              toast: true,
              showConfirmButton: false,
              timer: 1500,
              position: 'bottom-end',
            })

            fetchServiceLog(job_id)
          }
          //console.log(mydata);

        } catch (error) {
          console.log(error);
          Swal.fire({
            icon: 'error',
            text: 'Something went wrong ' + error.message,
            toast: true
          })
        }

      }
    })

  }
  const executeServiceLog = async () => {

    const { value: comment } = await Swal.fire({
      title: 'Enter a comment for Job #' + actionID_c,
      input: 'textarea',
      inputLabel: 'Comment',
      inputValue: '',
      customClass: {
        container: 'my-swal'
      },
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'You need to write something!'
        }
      }
    })

    if (comment) {
      // Swal.fire(`Your IP address is ${comment}`)
      // let arr = serviceLog;
      // console.log(arr, "arr->serviceLog")
      // let newArr = lodash.concat(arr, {comment:comment, created_at: moment().format("MMM Do YYYY, h:mm:ss a")})

      // _.reverse(newArr);
      // console.log(newArr, "we got")

      // setServiceLog((prev) => [...prev, ...newArr])
      // setServiceLog(newArr);

      //request UPDATE

      try {
        const mydata = await dataProvider2(
          "post",
          "/service-log/",
          { value: comment, id: actionID_c }
        );

        if (mydata.status === 200) {
          fetchServiceLog(actionID_c)

          Swal.fire({
            icon: 'success',
            text: mydata.data.message,
            toast: true,
            showConfirmButton: false,
            timer: 1500,
            position: 'bottom-end',
          })
        }
        //console.log(mydata);

      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          text: 'Something went wrong ' + error.message,
          toast: true
        })
      }
    }
    // setModalScreen("service_note");
    // handleOpenModal();
    // setSubject({ name: data.first_name, id: data.id });
    // setServiceNote(data.service_note);
  };

  const updateTechie = (d) => {

    return new Promise((resolve, reject) => {
    
      var technician = d.value;
      var id = d.info.id.id.replace("#", "");
      if (technician.length < 1) {
          reject(false);
      }
  
      if(technician === d.info.id.technician){
        reject(false);
      }
  
  
      Swal.fire({
        // title: 'Are you sure?',
        text: "Assign job " + d.info.id.id + " to " + technician + "?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `Yes`,
      }).then((result) => {
        if (result.isConfirmed) {
          doAPI("technician", {
            id: id,
            technician: technician,
            detail: d.info,
          })
            .then((res) => {
              // handleDialogClose();
  
              if (res.data.message) {
                Swal.fire({
                  text: res.data.message,
                  icon: 'success',
                  toast: true,
                  position: 'bottom-end',
                  'timer': 2000,
                })
              }

              resolve(technician);
  
            })
            .catch((error) => {
  
              Swal.fire({
                text: error.message,
                icon: 'warning',
                toast: true,
                position: 'bottom-end',
                'timer': 3000,
              })
  
            });
  
        } else {
          // props.refresher();\
          reject(false);
          // return false;
        }
  
      });

      
        
       
          
       
    })








  }


  const updateStatus_ = (data, e, detail) => {
    var status = e;
    if (e.target) status = e.target.value;
    if (!data) return;
    if (status === 'complete' && (!detail.invoice || detail.invoice === null)) {
      setToastMessage("An invoice is required to complete job");
      setIsToastOpen(true);
      setTimeout(() => {
        // props.refresher();
        loadData();
      }, 1500);
      return false;
    }

    // if (status === 'active' && (!detail.technician || detail.technician === null || detail.technician === "")) {
    //   setToastMessage("Assign this job to a technician before going ACTIVE.");
    //   setIsToastOpen(true);
    //   setTimeout(() => {
    //     props.refresher();
    //   }, 1500);
    //   return false;
    // }

    data.replace("#", "");
    Swal.fire({
      // title: 'Are you sure?',
      text: "Update job " + data + " to " + status + "?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, make it ${status}!`,
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log({ id: data.replace("#", ""), status: status });
        doAPI("status", {
          id: data.replace("#", ""),
          status: status,
          detail: detail,
        })
          .then((res) => {
            handleDialogClose();
            setToastMessage(res.data.message);
            setIsToastOpen(true);
            setTimeout(() => {
              //   loadBookings();
              // props.refresher();
              loadData();
            }, 1500);
          })
          .catch((error) => {
            //console.log(JSON.parse(error.request.response).message, "err")
            handleDialogClose();
            setToastMessage(JSON.parse(error.request.response)?.message);
            setIsToastOpen(true);
          });

      } else {
        return;
      }
    });
  };
  const updateStatus = (data, e, detail) => {
    // console.log(e.target.value);
    // console.log(data.replace("#", ""));
    var status = e;
    if (e.target) status = e.target.value;
    if (!data) return;

    doAPI("status", {
      id: data.replace("#", ""),
      status: status,
      detail: detail,
    })
      .then((res) => {
        handleDialogClose();
        setToastMessage(res.data.message);
        setIsToastOpen(true);
        setTimeout(() => {
          //   loadBookings();
          // props.refresher();
          loadData();
        }, 1500);
      })
      .catch((error) => {
        handleDialogClose();
        setToastMessage(error.data.message);
        setIsToastOpen(true);
      });

    // Swal.fire({
    //   title: 'Are you sure?',
    //   text: "Update this job status!",
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: `Yes, make it ${status}!`
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     doAPI("status", { id: data.replace("#", ""), status: status })
    //     .then((res) => {
    //       handleDialogClose();
    //       setToastMessage(res.data.message);
    //       setIsToastOpen(true);
    //       setTimeout(() => {
    //         //   loadBookings();
    //         props.refresher();
    //       }, 1500);
    //     })
    //     .catch((error) => {
    //       handleDialogClose();
    //       setToastMessage(error.data.message);
    //       setIsToastOpen(true);
    //     });
    //   }
    // })
  };

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    SelectAll: forwardRef((props, ref) => <SelectAll {...props} ref={ref} />),
    Refresh: forwardRef((props, ref) => (
      <Refresh
        // style={{ padding: 3, color: "white", backgroundColor: "green" }}
        {...props}
        ref={ref}
      />
    )),
  };

  let tableData = async query => {
    // let url = `http://localhost:1980/v2/inventory/products_` +
    //     `?per_page=${query.pageSize}` +
    //     `&page=${query.page + 1}` +
    //     `&search=${query.search}`;
    // const response = await fetch(url);
    // const result = await response.json();
    // console.log(result.data.total)
    // return {
    //     data: result.data.data,
    //     page: result.data.page - 1,
    //     totalCount: result.data.total,
    // };

    try {
      console.log(query, "QUERY")
      const result = await dataProvider("get", `/bookings_` +
        `?per_page=${query.pageSize}` +
        `&page=${query.page + 1}` +
        `&orderBy=${query.orderBy?.field}` +
        `&orderDirection=${query.orderDirection}` +
        `&search=${query.search}`, []);

      if (!result) return { data: null, page: null, totalCount: null }
      return {
        data: result.data.data.data,
        page: result.data.data.page - 1,
        totalCount: result.data.data.total,
      };
    } catch (error) {
      console.error(error);
      Swal.fire(
        undefined,
        error.message,
        'warning',
      )
      return [];
    }

  }

  async function loadData() {
    tableRef.current.onQueryChange()
  }

  //AlertDialog ----------------------------------------
  const [isOpen, setIsOpen] = React.useState(false);
  const handleDialogOpen = () => {
    setIsOpen(true);
  };
  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const handleDialogAgree = () => {
    updateStatus(actionID, "canceled", null);
    // handleDialogCancel();
  };
  const handleDialogCancel = () => {
    setIsOpen(false);
  };
  // -----------------------------------------------------


  const fetchServiceLog = async (id) => {
    if (!id) return false;
    try {
      const mydata = await dataProvider2(
        "get",
        "/service-log/" + id,
        null
      );
      if (mydata.status === 200) {
        setServiceLog(mydata.data)

      }

    } catch (error) {
      console.log(error);

    }
  }



  return (
    <>
      <MaterialTable
        tableRef={tableRef}
        style={{ fontSize: 14 }}
        title={tableTitle}
        // columns={
        //     profile?.permission > 2?view_admin:view_customer
        // }
        columns={[

          {
            title: "Registration", field: "registration", editable: "never",
            render: row => (
              <><span onClick={() => {
                copyElementText(row.registration);
              }} style={{ border: '1px solid #0c4bd3', borderRadius: 4, color: '#0c4bd3', background: 'white', padding: 3, width: 'auto' }}>{row.registration}</span>

                {
                  (profile.username === row.technician) ?
                    <BuildCircle style={{ color: 'red' }} /> : ''

                }
              </>
            )
          }, {
            title: "Name",
            field: "first_name",
            editable: "never",
            initialEditValue: "initial edit value",
          },
          { title: "Surname", field: "last_name", editable: "never" },

          {
            title: "VIN", field: "vin", editable: "never",
            render: row => (
              <Chip variant="outlined" onClick={() => {
                copyElementText(row.vin);
              }} label={row.vin} />
            )
          },
          { title: "ID", field: "id", editable: "never" },
          { title: "Mileage", field: "mileage" },
          {
            title: "Email",
            field: "email",
            editable: "never",
            hidden: true,
            searchable: true,
            render: (rowData) => (
              <a
                style={{ color: "#047bea" }}
                target="new_"
                href={"mailto:" + rowData.email}
              >
                {rowData.email}{" "}
              </a>
            ),
          },
          {
            title: "Contact",
            field: "contact",
            type: "numeric",
            editable: "never",
            hidden: true,
            searchable: true,
            render: (rowData) => (
              <a
                style={{ color: "#047bea" }}
                target="new_"
                href={"tel:" + rowData.contact}
              >
                {rowData.contact}{" "}
              </a>
            ),
          },
          { title: "Received", field: "created_at", editable: "never" },
          {
            title: "Completed",
            field: "complete_at",
            editable: "never",
            searchable: true,
            render: (rowData) =>
              rowData.status === "complete" &&
                rowData.complete_at !== "0000-00-00 00:00"
                ? rowData.complete_at
                : "",
          },
          {
            title: "Turnaround",
            editable: "never",

            render: (rowData) =>
              rowData.status === "complete" &&
                rowData.complete_at &&
                rowData.complete_at !== "0000-00-00 00:00" ? (
                <Chip
                  size="small"
                  variant="outlined"
                  label={moment
                    .duration(
                      moment(rowData.complete_at).diff(
                        moment(rowData.active_at)
                      )
                    )
                    .locale("en")
                    .humanize()}
                  color="primary"
                />
              ) : (
                ""
              ),
          }, {
            title: "Technician",
            field: "technician",
            editable: "never",
          },
          {
            title: "Status",
            field: "status",
            editable: "never",
            render: (rowData) => <Chip variant="outlined" color="default" label={rowData.status} />,
          },
          {
            title: "Services",
            field: "service",
            hidden: true,
            export: true,
            render: (rowData) => rowData.service.toString(),
          },
          {
            title: "Customer Note",
            field: "customer_note",
            hidden: true,
            export: true,
            render: (rowData) => rowData.service.toString(),
          },
        ]}
        onRowClick={(event, rowData, togglePanel) => {
          togglePanel()
        }
        }
        detailPanel={[
          {
            tooltip: "Show Services",
            // icon: () => null,
            onClick: () => alert(3),
            render: (userRowData) => {
              return (
                <div
                  style={{
                    fontSize: 14,
                    padding: 3,
                    textAlign: "left",
                    backgroundColor: "#fff",
                    border: "1px solid #aadaef",
                    height: "auto",
                  }}
                >
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Customer details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List component="nav" aria-label="main mailbox folders">


                        <ListItem>
                          <ListItemIcon>
                            <Phone />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <a
                                style={{ color: "#047bea" }}
                                target="new_"
                                href={"tel:" + userRowData.contact}
                              >
                                {userRowData.contact}
                              </a>
                            }
                          />
                        </ListItem>

                        {userRowData.email !== "none" ? (
                          <ListItem>
                            <ListItemIcon>
                              <Email />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <a
                                  style={{ color: "#047bea" }}
                                  target="new_"
                                  href={"mailto:" + userRowData.email}
                                >
                                  {userRowData.email}
                                </a>
                              }
                            />
                          </ListItem>
                        ) : (
                          ""
                        )}
                      </List>
                    </AccordionDetails>
                  </Accordion>

                  <List>
                    <ListItem>
                      <Typography gutterBottom variant="h5" >Services</Typography>
                    </ListItem>

                    <ol>
                      {JSON.parse(userRowData.service).map((v, i) => {
                        return (
                          <li
                            key={i}
                            style={{
                              padding: 9,
                              color: "#2b2b2b",
                              background: i % 2 === 0 ? "#eff0f2" : "#fff",
                            }}
                          >
                            {v}
                          </li>
                        );
                      })}
                    </ol>
                  </List>

                  {userRowData.status === "booked" ? (
                    <Chip
                      style={{ marginLeft: 20 }}
                      size="small"
                      label={
                        "Booked for " +
                        moment(userRowData.start).format("YYYY-MM-DD HH:mm")
                      }
                      color="primary"
                    />
                  ) : (
                    ""
                  )}

                  {/* SERVICES (EDITDABLE) */}

                  <div
                    style={{
                      paddingLeft: 6,
                      paddingRight: 10,
                      color: "#3b3b3b",
                      maxWidth: "90vw",
                    }}
                  >
                    {userRowData.customer_note?.length > 0 ? (

                      // <ListItem>
                      //   <ListItemAvatar>
                      //     <Avatar style={{ background: "#3368FA" }}>
                      //       <Note />
                      //     </Avatar>
                      //   </ListItemAvatar>
                      //   <ListItemText
                      //     primary={userRowData.customer_note}
                      //     secondary=""
                      //   />
                      // </ListItem>


                      <ListItem>



                        <Alert severity="info" icon={<Note />}>
                          <AlertTitle>Customers' note</AlertTitle>
                          {userRowData.customer_note}
                        </Alert>




                      </ListItem>


                    ) : (
                      ""
                    )}{" "}
                    {userRowData.service_note?.length > 0 ? (
                      <List>
                        {/* <ListItem>
                          <ListItemAvatar>
                            <Avatar style={{ background: "#ff1032" }}>
                              <Note />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={userRowData.service_note}
                            secondary=""
                          />
                        </ListItem> */}
                        <ListItem>


                          <Alert severity="error" icon={<Note />}>
                            <AlertTitle>Service comment</AlertTitle>
                            {userRowData.service_note}

                          </Alert>



                        </ListItem>
                      </List>
                    ) : (
                      ""
                    )}
                    <List>
                      <ListItem>


                        {userRowData.comment?.length > 0 ? (
                          <Alert severity="warning" icon={<NoteAlt />}>
                            <AlertTitle>Comments for customer</AlertTitle>
                            {userRowData.comment}
                          </Alert>

                        ) : (
                          ""
                        )}

                      </ListItem>
                    </List>
                    {/* Show available invoices */}
                    <List>
                      <ListItem>
                        {userRowData.invoice ? (


                          <Alert severity="success" icon={<AttachMoney />}>
                            <AlertTitle>Invoices</AlertTitle>
                            {userRowData.invoice.split(",").map(i => <Chip label={i} color="primary" onClick={() => history.push("/view-invoice/?id=" + i)} style={{ marginRight: 5 }} />)}
                          </Alert>


                        ) : <Alert severity="warning">No invoice generated!</Alert>}

                      </ListItem>
                      {/* <ListItem>
                        <ListItemAvatar>
                          {true ? (
                            <Avatar style={{ background: "#98c60b" }}>
                              <AttachMoney />
                            </Avatar>
                          ) : (
                            ""
                          )}
                        </ListItemAvatar>
                        <b>Invoices:&nbsp; </b>
                        {userRowData.invoice ? (
                          userRowData.invoice.split(",").map(i => <Chip label={i} color="primary" onClick={() => history.push("/view-invoice/?id=" + i)} style={{ marginRight: 5 }} />)
                        ) : <Alert severity="warning">No invoice generated!</Alert>}
                      </ListItem> */}
                    </List>
                    <List>
                      <ListItem>

                        <ListItemAvatar>

                          <Avatar style={{ background: '#454545' }}>
                            <Build />{" "}
                          </Avatar>

                        </ListItemAvatar>

                        <span style={{ fontSize: "1.1em" }}>Job Status:&nbsp; </span>

                        {userRowData.status !== "complete" &&
                          userRowData.status !== "canceled" &&
                          profile?.permission > 2 ? (
                          <select
                            style={{ fontSize: "1.2em", padding: 8 }}
                            onChange={(e) =>
                              updateStatus_(userRowData.id, e, userRowData)
                            }
                          >
                            {op.map(
                              (v, i) => {
                                if (
                                  userRowData.status === "complete" ||
                                  userRowData.status === "canceled"
                                ) {
                                  return <Chip
                                    size="small"
                                    label={userRowData.status}
                                    color="default"
                                  />

                                }

                                // if (rowData.status === "booked")
                                //   return (
                                //     <option selected value="booked">
                                //       booked
                                //     </option>
                                //   );
                                if (userRowData.status === v) {
                                  return (
                                    <option selected value={v}>
                                      {v}{" "}
                                    </option>
                                  );
                                }

                                if (v !== "canceled" && v !== "booked") {
                                  //available options
                                  return <option value={v}>{v}</option>;
                                }
                                //  return <option value={v}>{v}</option>;
                              }

                              // rowData.status === v ? (
                              //   v !== "canceled" && v !== "booked"  ? (
                              //     <option selected value={v}>{v} </option>
                              //   )) :  v === "booked" ? (
                              //     <option value="booked">booked</option>
                              //   ) : v !== "canceled" ? (
                              //   <option value={v}>{v}</option>
                              // ) : null
                            )}
                          </select>
                        ) : (
                          <Chip
                            size="small"
                            label={userRowData.status}
                            color="default"
                          />
                        )}
                      </ListItem>

                      <ListItem>

                        <ListItemAvatar>

                          <Avatar style={{ background: '#F55448' }}>
                            <Engineering />{" "}
                          </Avatar>

                        </ListItemAvatar>

                        <span style={{ fontSize: "1.1em" }}>Assigned to:&nbsp; </span>






                        {userRowData.status !== "complete" &&
                          userRowData.status !== "canceled" &&
                          profile?.permission > 3 ? (
                          <MyMenu info={{ id: userRowData }} trigger={updateTechie} title={userRowData.technician ? userRowData.technician : "Select a Technician"}
                            list={techie}
                          />
                          /*
                        <select
                          style={{ fontSize: "1.2em", padding: 8 }}
                          onChange={(e) =>
                            updateTechie(userRowData.id, e, userRowData)
                          }
                        >
                   
                          <option disabled selected value> -- select a technician -- </option>
                          {techie.map(
                            (v, i) => {
                              if (
                                userRowData.status === "complete" ||
                                userRowData.status === "canceled"
                              ) {
                                return <Chip
                                  size="small"
                                  label={userRowData.technician}
                                  color="secondary"
                                />

                              }

                              // if (rowData.status === "booked")
                              //   return (
                              //     <option selected value="booked">
                              //       booked
                              //     </option>
                              //   );
                              if (userRowData.technician === v.username) {
                                return (
                                  <option selected value={v.username}>
                                    {v.username}{" "}
                                  </option>
                                );
                              }

                              if (v !== "canceled" && v !== "booked") {
                                //available options
                                return <option value={v.username}>{v.username}</option>;
                              }
                              //  return <option value={v}>{v}</option>;
                            }


                          )}
                        </select>
                        */
                        ) : (
                          userRowData.technician ? <Chip
                            size="large"
                            label={userRowData.technician}
                            color="default"
                          /> : <Alert severity="warning">Not assigned to a technician</Alert>
                        )}
                      </ListItem>
                    </List>
                  </div>


                  <div
                    style={{ padding: 15, paddingLeft: 20, maxWidth: "90vw" }}
                  >
                    <Divider style={{ marginBottom: 9 }} />
                    {(userRowData.status === "received" ||
                      userRowData.status === "active") &&
                      profile?.permission > 3 ? (
                      //  <div style={{textAlign:'left'}}>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        className={classes.jobBtn}
                        onClick={() => {
                          history.push(
                            "/generate-invoice?id=" +
                            userRowData.id.substring(1)
                          );
                        }}
                      >
                        <Description style={{ width: 15, paddingRight: 3 }} />{" "}
                        Generate Invoice
                      </Button>
                    ) : // </div>
                      null}

                    {/* {userRowData.status !== "complete" &&
                      userRowData.status !== "canceled" &&
                      profile?.permission > 2 ? (
                      <Button
                        className={classes.jobBtn}
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          executeServiceLog();
                        }}
                      >
                        <EditRounded style={{ width: 15, paddingRight: 3 }} />{" "}
                        Service Log
                      </Button>
                    ) : (
                      ""
                    )} */}


                    {userRowData.status !== "complete" &&
                      userRowData.status !== "canceled" &&
                      profile?.permission > 2 ? (
                      <Button
                        className={classes.jobBtn}
                        size="small"
                        variant="outlined"
                        color="default"
                        onClick={() => {
                          executeCommentEdit(userRowData);
                        }}
                      >
                        <EditRounded style={{ width: 15, paddingRight: 3 }} />{" "}
                        Customer Comments
                      </Button>
                    ) : (
                      ""
                    )}{" "}
                    {userRowData.status !== "complete" &&
                      userRowData.status !== "canceled" &&
                      profile?.permission > 2 ? (
                      <Button
                        className={classes.jobBtn}
                        size="small"
                        variant="outlined"
                        color="default"
                        onClick={() => {
                          executeServiceNoteEdit(userRowData);
                        }}
                      >
                        <EditRounded style={{ width: 15, paddingRight: 3 }} />{" "}
                        Service Notes
                      </Button>
                    ) : (
                      ""
                    )}
                    {userRowData.status !== "complete" &&
                      userRowData.status !== "canceled" &&
                      profile?.permission > 3 ? (
                      <Button
                        className={classes.jobBtn}
                        size="small"
                        variant="outlined"
                        color="default"
                        onClick={() => {
                          executeServiceEdit(userRowData);
                        }}
                      >
                        <EditRounded style={{ width: 15, paddingRight: 3 }} />{" "}
                        Services
                      </Button>
                    ) : (
                      ""
                    )}
                    {userRowData.status !== "complete" &&
                      userRowData.status !== "canceled" &&
                      profile?.permission > 3 ? (
                      <Button
                        className={classes.jobBtn}
                        size="small"
                        variant="outlined"
                        color="default"
                        onClick={() => {
                          setActionID(userRowData.id);
                          handleDialogOpen();
                        }}
                      >
                        <DeleteOutline style={{ width: 15, paddingRight: 3 }} />{" "}
                        Cancel
                      </Button>
                    ) : (
                      ""
                    )}
                    {profile?.permission > 2 ? (
                      <Button
                        className={classes.jobBtn}
                        size="small"
                        variant="outlined"
                        color="default"
                        onClick={() => {
                          // addPhoto();
                          let id = userRowData.id.replace("#", "");
                          activeID = id;
                          setActionID(id);
                          setOpenPhotos(true);
                          loadPhotos(id);
                        }}
                      >
                        <PhotoLibrary style={{ width: 15, paddingRight: 3 }} />{" "}
                        Gallery
                      </Button>
                    ) : (
                      ""
                    )}
                    {profile?.permission >= 4 ? (
                      <Button
                        className={classes.jobBtn}
                        size="small"
                        variant="outlined"
                        color="default"
                        onClick={() => {
                          // addPhoto();
                          let id = userRowData.id.replace("#", "");
                          activeID_c = id;
                          setActionID_c(id);
                          setOpenSL(true);
                          //                           let id = userRowData.id.replace("#", "");
                          //  activeID_c = id;
                          //                           alert(activeID_c)

                          fetchServiceLog(id)
                          // loadPhotos(id);
                        }}
                      >
                        <RateReview style={{ width: 15, paddingRight: 3 }} />{" "}
                        Log Service Comment
                      </Button>



                    ) : (
                      ""
                    )}

                    {/* {userRowData.status !== "complete" &&
                    userRowData.status !== "canceled" &&
                    profile?.permission > 3 ? (
                      <Button
                        className={classes.jobBtn}
                        size="small"
                        variant="outlined"
                        color="default"
                        onClick={() => {
                          // addPhoto();
                          // let aid = userRowData.id.replace("#", "");
                          // console.log(aid, "==============")
                          activeID = userRowData.id.replace("#", "");
                          // setFormData({ ...formData, job_id: userRowData.id.replace("#", "") });
                          // setFormData({job_id: userRowData.id.replace("#", "") });
                          // setActionID(aid);
                          myFile.current.click();
                        }}
                      >
                        <AddAPhotoIcon style={{ width: 15, paddingRight: 3 }} />{" "}
                        Add Photo
                      </Button>):('')} */}
                    <input
                      onChange={handleFormChange}
                      type="file"
                      name="photo"
                      ref={myFile}
                      style={{ visibility: "hidden" }}
                    />
                  </div>
                </div>
              );
            },
          },
        ]}
        icons={tableIcons}
        data={tableData ? tableData : null}
        editable={
          profile?.permission > 3
            ? {
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  putService(newData)
                    .then((res) => {
                      setToastMessage(res.data.message);
                      setIsToastOpen(true);
                      // props.refresher();
                      loadData();
                      resolve();
                    })
                    .catch((error) => {
                      setToastMessage(error.message);
                      setIsToastOpen(true);
                      reject();
                    });
                }),
            }
            : null
        }
        options={{
          defaultExpanded: true,
          pageSize: 10,
          pageSizeOptions: [10, 20, 50, 100, 500],
          sorting: true,
          search: true,
          exportButton: true,
          rowStyle: (rowData) => {
            // console.log(rowData)
            // if (rowData.tableData.id % 2 === 0) {
            //   return { backgroundColor: "#fcf4f6" };
            // }
            // console.log(rowData);

            if (rowData.status === "booked") {
              return { backgroundColor: "#e0f5ff" };
            }

            if (rowData.status === "active") {
              return { backgroundColor: "#fce3b0" };
            }

            if (rowData.status === "received") {
              return { backgroundColor: "#fcfcfc" };
            }

            if (rowData.status === "complete") {
              return { backgroundColor: "#eafcea" };
            }
            if (rowData.status === "canceled") {
              return { backgroundColor: "#efefef" };
            }
          },
        }}
        actions={[
          {
            tooltip: "Refresh",
            icon: tableIcons.Refresh,
            position: "toolbar",
            onClick: () => {
              loadData();
            },
          }
          // {
          //   tooltip: "Refresh",
          //   icon: tableIcons.Refresh,
          //   position: "toolbar",
          //   onClick: () => {
          //     props.refresher();
          //   },
          // },
        ]}
      />

      <Toast
        isOpen={isToastOpen}
        handleClose={handleToastClose}
        message={toastMessage}
      />
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <React.Fragment>
          <div
            className={classes.paper}
            style={{
              modalStyle,
              display: modalScreen === "comment" ? "block" : "none",
            }}
          >
            <h3 id="simple-modal-title">Make a comment for {subject.name}</h3>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Comment"
                  variant="outlined"
                  className={classes.flow}
                  name="Comment"
                  value={mcomment}
                  onChange={handleComment}
                  id="comment"
                  autoComplete="off"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  rows={7}
                  multiline
                  inputProps={{
                    autoComplete: "off",
                    form: {
                      autoComplete: "off",
                    },
                    // onBlur:(e) => commentBlur(userRowData.id, e)
                  }}
                />
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 60 }}>
                <Divider style={{ marginBottom: 10 }} />
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={updateComment}
                >
                  Save
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  variant="contained"
                  size="large"
                  color="default"
                  onClick={handleCloseModal}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </div>

          <div
            className={classes.paper}
            style={{
              modalStyle,
              display: modalScreen === "service_note" ? "block" : "none",
            }}
          >
            <h3 id="simple-modal-title">
              Make a service note for {subject.name}
            </h3>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Service Note"
                  variant="outlined"
                  className={classes.flow}
                  name="Service Note"
                  value={snote}
                  onChange={handleServiceNote}
                  id="service_note"
                  autoComplete="off"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  rows={7}
                  multiline
                  inputProps={{
                    autoComplete: "off",
                    form: {
                      autoComplete: "off",
                    },
                    // onBlur:(e) => commentBlur(userRowData.id, e)
                  }}
                />
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 60 }}>
                <Divider style={{ marginBottom: 10 }} />
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={updateServiceNote}
                >
                  Save
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  variant="contained"
                  size="large"
                  color="default"
                  onClick={handleCloseModal}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </div>

          <div
            // style={}
            className={classes.paper}
            style={
              (modalStyle,
                { display: modalScreen === "services" ? "block" : "none" })
            }
          >
            <h3 id="simple-modal-title">Edit services for {subject.name}</h3>

            <Grid container spacing={3}>
              {serviceList?.map((data, index, arr) => {
                // setService({ ...service, [data.description]: false});

                return (
                  <React.Fragment>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      key={data.title + "1"}
                      style={{ textAlign: "left", border: "0px solid #f3f3f3" }}
                    >
                      <FormControlLabel
                        required
                        key={data.title + "-1"}
                        control={
                          <Checkbox
                            key={data.title + "--1"}
                            checked={
                              service && service[data.description]
                                ? service[data?.description]
                                : false
                            }
                            onChange={handleChange}
                            name={data.description}
                          />
                        }
                        label={
                          data.description[0] !==
                            arr[index - 1]?.description[0] ? (
                            <span key={data.title + "---1"}>
                              <b
                                style={{
                                  color: "#000",
                                  fontSize: "1.2em",
                                  textDecoration: "underline",
                                }}
                              >
                                {data.description[0]}
                              </b>
                              {data.description.substring(1)}
                            </span>
                          ) : (
                            data.description
                          )
                        }
                      />
                    </Grid>
                  </React.Fragment>
                );
              })}

              <Grid
                item
                xs={12}
                style={{ textAlign: "center", marginBottom: 60 }}
              >
                <Divider style={{ marginBottom: 10 }} />
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={updateServices}
                >
                  Save
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  variant="contained"
                  size="large"
                  color="default"
                  onClick={handleCloseModal}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
      </Modal>
      <AlertDialog
        isOpen={isOpen}
        handleClose={handleDialogClose}
        handleAgree={handleDialogAgree}
        handleCancel={handleDialogCancel}
        title={"Cancel Job " + actionID}
        subtitle="Are you sure you want to cancel this job?"
      />

      {/* Service Log Dialog */}
      <div>
        <Dialog
          fullScreen
          disableEnforceFocus
          open={openSL}
          onClose={handleCloseSL}
          style={{ zIndex: 1501 }}
        // TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseSL}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Box display="flex" flexGrow={1}>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  Service Log
                </Typography>
              </Box>

              <Button
                autoFocus
                color="inherit"
                onClick={() => {
                  executeServiceLog();
                }}
              >
                <CommentOutlined style={{ width: 15, paddingRight: 3 }} /> Add
                Comment
              </Button>
            </Toolbar>
          </AppBar>


          <div style={{ padding: 2, paddingTop: 70, maxWidth: "100vw" }}>
            <LinearProgress
              style={{ visibility: fileProgress, marginBottom: 15 }}
            />

            {serviceLog?.length > 0 ? (
              serviceLog?.map((i, v) =>
                <ListItem button onClick={() => profile?.permission >= 5 ? removeServiceLog(i.id, i.job_id, i.message) : false} >

                  <ListItemText primary={i.message} secondary={moment(i.created_at).format("MMM Do YYYY, h:mm:ss a")} />
                </ListItem>
              )

            ) : (
              <div style={{ textAlign: "center", marginTop: 120 }}>
                <img alt="footer" src={footer_logo} width={120} />
              </div>
            )}

          </div>
        </Dialog>
      </div>


      {/* Photo Dialog */}
      <div>
        <Dialog
          fullScreen
          open={openPhotos}
          onClose={handleClosePhotos}
        // TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClosePhotos}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Box display="flex" flexGrow={1}>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  Gallery
                </Typography>
              </Box>

              <Button
                autoFocus
                color="inherit"
                onClick={() => {
                  myFile.current.click();
                }}
              >
                <AddAPhotoIcon style={{ width: 15, paddingRight: 3 }} /> Add
                Photo
              </Button>
            </Toolbar>
          </AppBar>
          {/* <List>
          <ListItem button>
            <ListItemText primary="Phone ringtone" secondary="Titania" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Default notification ringtone"
              secondary="Tethys"
            />
          </ListItem>
        </List> */}

          <div style={{ padding: 2, paddingTop: 70, maxWidth: "100vw" }}>
            <LinearProgress
              style={{ visibility: fileProgress, marginBottom: 15 }}
            />

            {photos?.length > 0 ? (
              <SimpleReactLightbox>
                <SRLWrapper>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} style={{ maxWidth: "99vw" }}>
                      {photos.map((p) => (
                        <Grid item xs={12} md={3}>
                          <a href={p.src}>
                            <img style={{ width: "100%" }} src={p.src} />
                          </a>
                          <Button
                            color="inherit"
                            onClick={() => {
                              deletePhoto(p.id);
                            }}
                            style={{ textTransform: "capitalize" }}
                          >
                            <Delete style={{ width: 18, color: "#777" }} />
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>

                  {/* <a href="link/to/the/full/width/image.jpg">
          <img src="src/for/the/thumbnail/image.jpg" alt="Umbrella" />
        </a>
        <a href="link/to/the/full/width/image_two.jpg">
          <img src="src/for/the/thumbnail/image_two.jpg" alt="Blue sky" />
        </a> */}
                </SRLWrapper>
              </SimpleReactLightbox>
            ) : (
              <div style={{ textAlign: "center", marginTop: 120 }}>
                <img alt="footer" src={footer_logo} width={120} />
              </div>
            )}
            {/* {photos?.length > 0? <Gallery
                       customControls={[
                         <button key="deleteImage" onClick={deleteImage}>
                           Delete Image
                         </button>,
                       ]}
                      images={photos}
                    />:<div style={{textAlign:'center', marginTop:100}}><img alt="footer" src={footer_logo} width={120} /></div>} */}
          </div>
        </Dialog>
      </div>
    </>
  );
}
