import React from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';

const AdaptDialog = props => {
  const { 
    isOpen = false, 
    onClose = () => {}, 
    title = 'Dialog', 
    children, 
    ...rest 
  } = props;

  const handleClose = event => {
    onClose(event);
  };

  return (
    <Dialog 
    maxWidth="lg"
    fullWidth

    onClose={handleClose} open={isOpen}>

      <DialogTitle>{title}</DialogTitle>
      {children}
    </Dialog>
  );
}

export default AdaptDialog;