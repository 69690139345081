import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Engineering, Person2Outlined } from '@mui/icons-material';
import { ListItemIcon } from '@material-ui/core';

export default function MyMenu(props) {
    const { title = "", list = [], trigger, info } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [message, setMessage] = React.useState(title);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSelect = async (value) => {
        try {
            const r = await trigger({ value, info });
            if (r) setMessage(r)
            setAnchorEl(null);
        } catch (error) {
            console.error(error)
        }

    };

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {message}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {
                    list.map(v => <MenuItem onClick={() => handleSelect(v.username)}>
                        <ListItemIcon>
                            <Person2Outlined fontSize="small" />
                        </ListItemIcon>{v.username}</MenuItem>)
                }

            </Menu>
        </div>
    );
}