import React, { forwardRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Chip, Typography, Paper, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { dataProvider, getProfile, config, getPDF } from "../util";
import Toast from "../components/Toast";
import AlertDialog from "../components/AlertDialog";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteRounded from "@material-ui/icons/DeleteRounded";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import SelectAll from "@material-ui/icons/SelectAll";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Invoice from "@material-ui/icons/Description";
import { Refresh, AttachMoney, MoneyOff, AssignmentTurnedIn } from "@material-ui/icons";
import { Card, Divider, Stack, Tab, Tabs } from "@mui/material";
import Label from "../features/label"
import InvoiceAnalytic from "../features/InvoiceAnalytic";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
    paddingTop: 60,
    // padding: 30,
    // background: "#f3f3f3"
  },
  formRoot: {
    "& > *": {
      //   margin: theme.spacing(1),
      //   minWidth: '100%',
      //   background: 'red'
    },
  },
  flow: {
    width: "100%",
  },
  icon: {
    color: "red",
  },
  section1: {
    marginTop: 20,
  },
  subTitle: {
    color: "#0635C9",
    textAlign: "left",
    fontSize: "1.2em",
  },
}));

export default function ViewInvoices() {
  let history = useHistory();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [stats, setStats] = useState({
    paid: [{
      invoices: 0,
      total: 0
    }],
    unpaid: [
      {
        invoices: 0,
        total: 0
      }
    ]
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rowdata, setRowData] = useState({});
  // const op = ["open", "paid", "write-off"];

  //Toast ----------------------------------------
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  // const handleToastOpen = () => {
  //   setIsToastOpen(true);
  // };
  const handleToastClose = () => {
    setIsToastOpen(false);
  };
  // -----------------------------------------------------

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteRounded {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    // SelectAll: forwardRef((props, ref) => <SelectAll {...props} ref={ref} />),
    MoneyOff: forwardRef((props, ref) => <MoneyOff style={{ color: "red" }} {...props} ref={ref} />),
    AttachMoney: forwardRef((props, ref) => <AttachMoney style={{ color: "#02c39a" }}  {...props} ref={ref} />),
    SelectAll: forwardRef((props, ref) => <SelectAll {...props} ref={ref} />),
    Refresh: forwardRef((props, ref) => (
      <Refresh
        // style={{ padding: 3, color: "white", backgroundColor: "green" }}
        {...props}
        ref={ref}
      />
    )),
  };

  const [profile, setProfile] = React.useState({ username: "", permission: 0 });
  useEffect(() => {
    loadProfile();
    loadData();
  }, []);

  async function loadProfile() {
    const profile = await getProfile();
    setProfile(profile);
  }

  async function loadData(state = 'all') {
    try {
      const mydata = await dataProvider("get", "/invoices/" + state, []);
      const stats = await dataProvider("get", "/v2/util/invoice-status/", []);
      console.log(stats, "stats")
      setStats(stats.data.data);
      setData(mydata.data);
    } catch (error) {
      console.log(error);
      setToastMessage(error.message);
      setIsToastOpen(true);
    }
  }

  //   const ref = React.useRef(0);
  //     React.useEffect(() => {
  //         ref.current += 1;
  //     });

  //   const vin_ref = useRef(0);

  /**
   * Delete a item
   */
  const deleteItem = (rowData) => {
    return new Promise((resolve, reject) => {
      dataProvider("delete", "/invoice", rowData)
        .then((res) => {
          setToastMessage(res.data.message);
          setIsToastOpen(true);
          loadData();
          setDialogOpen(false);
          setRowData({});
          resolve();
        })
        .catch((error) => {
          setToastMessage(error.message);
          setIsToastOpen(true);
          setDialogOpen(false);
          setRowData({});
          reject();
        });
    });
  };
  const view_admin = [
    {
      title: "Status",
      render: (rowData) =>
        (rowData.total <= 0) ? (
          //  (rowData.total === "0"  || rowData.total === "0.0")? (
          <Chip size="small" label="Paid" variant="outline" color="primary" />
        ) : (
          <Chip size="small" label="Due" variant="outline" color="secondary" />
        ),
    },

    {
      title: "Invoice#",
      field: "id",
      editable: "never",
    },
    { title: "Job ID", field: "request_id", editable: "never" },
    { title: "First Name", field: "first_name" },
    { title: "Last Name", field: "last_name" },
    // {
    //   title: "Surname",
    //   field: "last_name",
    //   render: (rowData) => rowData.last_name.toString(),
    // },
    { title: "Registration", field: "registration" },
    // { title: "Due", type: "currency", field: "total" },
    {
      title: "Due", type: "currency", field: "total",
      render: (rowData) => (<span style={{ color: Number(rowData.total) > 0 ? '#ff0054' : '' }}>{Number(rowData.total).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}</span>)
    },
    {
      title: "Paid", type: "currency", field: "payment",
      render: (rowData) => (<b style={{ color: '#02c39a' }}>{rowData.payment.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}</b>)
    },
    {
      title: "Date",
      field: "created_at",
      editable: "never",
      render: (rowData) => (
        <div style={{ minWidth: 100 }}>{rowData.created_at}</div>
      ),
    },
    // { title: "Payments", type: "currency", field: "payment" },
    // {
    //     title: "Status",
    //     field: "invoice_status",
    //     editable: "never",
    //     render: (rowData) =>
    //     rowData.invoice_status !== "write-off" &&
    //         rowData.invoice_status !== "paid" &&

    //       profile?.permission > 2 ? (
    //         <select onChange={(e) => updateStatus(rowData.id, e)}>
    //           {op.map(
    //             (v, i) => {
    //                 let state = rowData.invoice_status.toString();
    //               if (state === v) {
    //                 return (
    //                   <option selected value={v}>
    //                     {v}
    //                   </option>
    //                 );
    //               }
    //              if (state !== v) {
    //                 return <option x='1' value={v}>{v}</option>;
    //               }
    //             }
    //           )}
    //         </select>
    //       ) : (
    //         rowData.invoice_status
    //       ),
    //   },

    // { title: "Updated", field: "updated_on", editable: "never" },
    { title: "VIN", field: "vin", hidden: true, searchable: true },

    // { title: "VIN", field: "vin",
    // render: (rowData) => (
    //   <div onClick={()=> {copyElementText(rowData.vin);}}>
    //     {rowData.vin}{" "}
    //   </div>
    // ) }
  ];

  const TABS = [
    { value: 'all', label: 'All', color: 'info', count: 655 },
    { value: 'paid', label: 'Paid', color: 'success', count: 757 },
    { value: 'unpaid', label: 'Unpaid', color: 'warning', count: 6565 },
    { value: 'overdue', label: 'Overdue', color: 'error', count: 7554 },
    { value: 'draft', label: 'Draft', color: 'default', count: 5445 },
  ];


  return (
    <div className={classes.root}>
      {/* <form className={classes.formRoot} onSubmit={handleSubmit}> */}
      <Grid container spacing={2}>

        <Grid item xs={12}>


          <Card sx={{ mb: 1 }}>
            {/* <Scrollbar> */}
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
              sx={{ py: 2 }}
            >
              {/* <InvoiceAnalytic
                title="Total"
                total={55}
                percent={100}
                price={66}
                icon="ic:round-receipt"
                // color={theme.palette.info.main}
              /> */}

              <InvoiceAnalytic
                title="Paid in last 7 days"
                total={stats?.paid[0].invoices}
                percent={77}
                price={stats?.paid[0].total}
                icon="eva:checkmark-circle-2-fill"
                color={'green'}
              />

              <InvoiceAnalytic
                title="Unpaid"
                total={stats?.unpaid[0].invoices}
                percent={88}
                price={stats?.unpaid[0].total}
                icon="eva:clock-fill"
                color={'red'}
              // color={theme.palette.warning.main}
              />

              {/* <InvoiceAnalytic
                title="Overdue"
                total={66}
                percent={77}
                price={888}
                icon="eva:bell-fill"
                // color={theme.palette.error.main}
              /> */}

              {/* <InvoiceAnalytic
                title="Draft"
                total={877}
                percent={777}
                price={999}
                icon="eva:file-fill"
                // color={theme.palette.text.secondary}
              /> */}
            </Stack>
            {/* </Scrollbar> */}
          </Card>

        </Grid>

        <Grid item xs={12}>
          <MaterialTable
            style={{ fontSize: 14 }}
            title="Invoices"
            columns={view_admin}
            icons={tableIcons}
            data={data ? data : null}
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 20, 50, 100, 500],
              sorting: true,
              search: true,
              exportButton: false,
              rowStyle: (rowData) => {
                // console.log(rowData)
                // if (rowData.tableData.id % 2 === 0) {
                //   return { backgroundColor: "#fceffc" };
                //   return { backgroundColor: "#eaf9f7" };
                // }

                if (rowData.total === "0" || rowData.total === "0.0") {
                  return { backgroundColor: "#f8fcea" };
                }

                if (rowData.invoice_status === "open") {
                  return { backgroundColor: "#fff" };
                }

                if (rowData.invoice_status === "write-off") {
                  return { backgroundColor: "#ffdbe6" };
                }
              },
            }}
            // onRowClick={(event, rowData, togglePanel) => {
            // window.open(config.domain+'/invoice/'+rowData.id);
            // if(rowData.invoice_status === 'open') history.push('view-invoice/?id='+rowData.id)

            // }}
            actions={[
              {
                tooltip: "Refresh All Invoices",
                icon: tableIcons.Refresh,
                position: "toolbar",
                onClick: () => {
                  loadData();
                },

                // onClick: (evt, data) => {(doAll)?setOpenD(true):setShowSnack(true)}
                // onClick: (evt, data) => alert('Confirm delivery for ' + data.length + ' packages')
              },

              {
                icon: tableIcons.MoneyOff,
                tooltip: 'Due Invoices',
                isFreeAction: true,
                onClick: () => {
                  loadData('due');
                },
              },

              {
                icon: tableIcons.AttachMoney,
                tooltip: 'Paid Invoices',
                isFreeAction: true,
                onClick: () => {
                  loadData('paid');
                },
              },



              // {
              //   icon: Edit,
              //   tooltip: "Edit Invoice",
              //   onClick: (event, rowData) => {
              //           // window.open(config.domain+'/invoice/'+rowData.id);
              //           // getPDF(config.domain+'/invoice/'+rowData.id);
              //           if(rowData.invoice_status === 'open') history.push('view-invoice/?id='+rowData.id)

              //   }
              // },

              // (rowData) => {
              // return rowData.invoice_status === "open"
              //   ? {
              //       icon: Edit,
              //       onClick: () =>
              //         history.push("view-invoice/?id=" + rowData.id),
              //     }
              //   : {
              //       icon: Invoice,
              //       tooltip: "View Invoice",
              //       onClick: (event, rowData) => {
              //         // window.open(config.domain+'/invoice/'+rowData.id);
              //         getPDF(config.domain + "/invoice-pdf/" + rowData.id);
              //       },
              //     };
              // },

              (rowData) => ({
                icon: Invoice,
                tooltip: "View Invoice",
                onClick: (event, rowData) => {
                  // window.open(config.domain+'/invoice/'+rowData.id);
                  getPDF(config.domain + "invoice-pdf/" + rowData.id);
                },
              }),
              // (rowData) => ( profile?.permission > 3 && (rowData.total > 0)
              (rowData) => (profile?.permission > 3
                ? {
                  icon: Edit,
                  onClick: () => history.push("view-invoice/?id=" + rowData.id),
                } : null),

              (rowData) =>
                profile?.permission > 4 && (rowData.total > 0)
                  ? {
                    icon: tableIcons.Delete,
                    tooltip: "Delete Invoice",
                    onClick: (event, rowData) => {
                      setRowData(rowData);
                      setDialogOpen(true);
                    },
                  }
                  : null,

              // (rowData) => ({
              //   icon: Invoice,
              //   tooltip: "View Invoice",
              //   onClick: (event, rowData) => {
              //           // window.open(config.domain+'/invoice/'+rowData.id);
              //           getPDF(config.domain+'/invoice/'+rowData.id);

              //   }
              // }),
            ]}
          />
        </Grid>
      </Grid>
      {/* </form> */}
      <Toast
        isOpen={isToastOpen}
        handleClose={handleToastClose}
        message={toastMessage}
      />

      <AlertDialog
        isOpen={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        handleAgree={() => deleteItem(rowdata)}
        handleCancel={() => setDialogOpen(false)}
        title="Confirmation"
        subtitle={
          "Delete Invoice #" + rowdata.id + " for " + rowdata.first_name + "?"
        }
        ok="Yes"
        cancel="Cancel"
      />
    </div>
  );
}
